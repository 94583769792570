import { Link } from "react-router-dom";
// import "./menu_item_content_2.css";

function Menu_item_content_2({ obj }) {
  return (
    <div className="flex flex-col">
      {/* <div className="text-left title-listgame relative pl-4">Danh sách</div> */}
      {/* <div className="p-4"> */}
      {obj.map((item, i) => {
        return (
          <Link
            to={item.link}
            key={i}
            className="flex justify-between h-[10.2rem] md:h-[8.6rem] sm:h-[8.6rem] bg-menu-color rounded-2xl my-3 shadow"
          >
            <div className="ml-6 text-white flex flex-col items-start pt-4">
              <span className="text-[2.2rem] md:text-[1.8rem] sm:text-[1.7rem] font-bold uppercase">
                {item.title}
              </span>
              <span className="text-[1.3rem] md:text-[1.1rem] sm:text-[1rem]">
                {item.type}
              </span>
              <span className="text-[1.3rem] md:text-[1.1rem] sm:text-[1rem] text-left">
                {item.description}
              </span>
            </div>
            <img src={item.img} className="h-full" />
          </Link>
        );
      })}
      {/* </div> */}
    </div>
  );
}

export default Menu_item_content_2;
