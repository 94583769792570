import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkToken } from "../utils/function";

const domain = process.env.REACT_APP_DOMAIN;

export const apiAgency = createApi({
  reducerPath: "apiAgency",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/daily" }),

  endpoints: builder => ({
    getSettingAgency: builder.query({
      query: () => ({
        url: `/check`,
        headers: headers(),
        method: "GET",
      }),
    }),
  }),
});

export const { useGetSettingAgencyQuery } = apiAgency;
