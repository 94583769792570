import dia from "../../../assets/images/dia_xocdia.png";
import chen from "../../../assets/images/chen_xocdia.png";
import "./index.css";
import XocdiaBet from "../../../components/xocdia/Bet";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import History from "../../../components/xocdia/History";
import useWebSocket from "react-use-websocket";
import { useNavigate, useParams } from "react-router-dom";
import Dialog__NoticeWin from "../../../components/modal/Dialog_notice_win";
import { Xocdia_Cuadat } from "../../../utils/const";
import Loading from "../../../components/common/loading";
import Dialog_confirm_info_bet from "../../../components/modal/Dialog_confirm_info_bet";
import { toast } from "react-toastify";
const socketUrl = process.env.REACT_APP_WEBSOCKET;
function Xocdia() {
  const navigate = useNavigate();
  let { theloai } = useParams();
  // if (!theloai || (theloai != 3 && theloai != 5)) {
  //   navigate("/");
  // }

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const [shake, setShake] = useState(false);

  const [phien, setPhien] = useState(0);
  const [time, setTime] = useState(0);
  const [created, setCreated] = useState("");

  const [history, setHistory] = useState([]);
  const [historyUserBet, setHistoryUserBet] = useState([]);
  const [pageUser, setPageUser] = useState(1);
  const [totalPageUser, setTotalPageUser] = useState(1);

  const [tiLe, setTiLe] = useState();
  const [dataModalWin, setDataModalWin] = useState({});
  const [modalWin, setModalWin] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleModalWin = () => {
    setModalWin(!modalWin);
  };

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const handleLogin = () => {
    sendJsonMessage({
      auth: { token: localStorage.getItem("auth") },
    });
  };
  const handleGetTiLe = () => {
    sendJsonMessage({
      xocdia: { getTiLe: true },
    });
  };
  const handleGetHistoryUserBet = () => {
    if (pageUser > totalPageUser) return;
    sendJsonMessage({ xocdia: { history: { theloai, trang: pageUser } } });
  };

  useEffect(() => {
    handleLogin();
    handleGetTiLe();
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);

      if (data?.xocdia?.cuoc) {
        if (data?.xocdia?.cuoc?.success) {
          handleGetHistoryUserBet();
        }
      }

      if (data?.notice?.msg === "Đăng nhập thành công") {
        handleGetHistoryUserBet();
      }

      if (data?.notice?.msg === "Bạn đã bị khoá cược!") {
        toast.error("Bạn đã bị khóa cược");
        return;
      }
      if (data?.notice?.msg === "Tài khoản đã bị khóa!") {
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        toast.error("Tài khoản đã bị khóa");
        setTimeout(() => {
          window.location.assign("/login");
        }, 2000);
      }

      if (data?.xocdia?.noticeData) {
        const a = Object.values(data?.xocdia?.noticeData)[0];
        const dataWin = Object.values(a)[0];
        dataWin.cuadat = Xocdia_Cuadat[dataWin.cuadat];
        dataWin.theloai = dataWin.theloai + " phút";
        setDataModalWin(dataWin);
        setModalWin(true);
      }

      if (data?.xocdia?.tile && !tiLe) {
        setTiLe(data?.xocdia?.tile);
      }
      if (data?.xocdia?.[theloai]) {
        if (data?.xocdia?.[theloai]?.kq) {
          setOpen(true);
          setShake(false);
        }

        if (data?.xocdia?.[theloai]?.phien) {
          setPhien(data?.xocdia?.[theloai]?.phien);
          setTime(data?.xocdia?.[theloai]?.time);
          setCreated(data?.xocdia?.[theloai]?.created_at);
          setLoading(false);
        }
        if (data?.xocdia?.[theloai]?.lastResult?.length > 0)
          setHistory(data?.xocdia?.[theloai]?.lastResult || 0);

        if (data?.xocdia?.[theloai]?.historyUser) {
          setTotalPageUser(data?.xocdia?.[theloai]?.historyUser?.totalPage);
          if (pageUser === 1) {
            setHistoryUserBet(data?.xocdia?.[theloai]?.historyUser?.data);
          } else {
            setHistoryUserBet((prev) => [
              ...prev,
              ...data?.xocdia?.[theloai]?.historyUser?.data,
            ]);
          }
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    handleGetHistoryUserBet();
  }, [open, pageUser]);

  useEffect(() => {
    if (time === theloai * 60 - 10) {
      setOpen(false);
    }
  }, [time]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setShake(true);
      }, 2000);
    }
  }, [open]);

  return (
    <>
      <Loading loading={loading} />

      <div className="main xd mt-5 w-full">
        <div className="box-bg-game">
          <div className="bg-game">
            <div
              data-v-45adac70
              className="v_circle taste_lottery_countdown xodi  v_circle_show v_circle_PC"
            >
              <div data-v-45adac70 className="mask half">
                <div
                  data-v-45adac70
                  className={`fill fix ${
                    time < 6 ? "bg-[#e22d41]" : "bg-[#36e957]"
                  }`}
                  style={{ transform: `rotate(${time < 0 ? 0 : time}deg)` }}
                ></div>
              </div>
              <div
                data-v-45adac70
                flex="main:center cross:center"
                className="pv"
              >
                <span
                  data-v-45adac70
                  className={`progress ${
                    time < 6 ? "text-[#e22d41]" : "text-[#36e957]"
                  }`}
                >
                  {time < 0 ? 0 : time}
                </span>
              </div>
            </div>

            <div className="boxdia">
              <img
                src={chen}
                className="point"
                style={{
                  animation: `4s ease 0s 1 normal forwards running ${
                    open && !shake
                      ? "movePoint"
                      : !open && !shake
                      ? "movePointBack"
                      : shake
                      ? "shake"
                      : ""
                  } `,
                }}
              />
              <img
                src={dia}
                className="check"
                style={{
                  animation: `4s ease 0s 1 normal forwards running ${
                    shake ? "shake1" : ""
                  }`,
                }}
              />

              <div className="history_xucsac result-dia">
                {history?.[0]?.ketqua.split(",")?.map((item, index) => {
                  return (
                    <div className={item == 0 ? "a0" : "a1"} key={index}></div>
                  );
                })}
                {/* <div className="a0"></div>
              <div className="a0"></div>
              <div className="a0"></div>
              <div className="a1"></div> */}
              </div>
            </div>

            <div className="timexd">
              <p className="text-[20px] xs:text-[16px] ">Phiên {phien}</p>
              <p className="text-[16px] xs:text-[12px]  opacity-70">
                {dayjs(created || Date.now()).format("YYYY/MM/DD HH:mm")}
              </p>
            </div>
          </div>
        </div>

        <XocdiaBet
          tile={tiLe}
          theloai={theloai}
          time={time}
          sendMsg={sendJsonMessage}
          lastMessage={lastMessage}
          setPageUser={setPageUser}
        />
        <History
          phien={phien}
          created={created}
          history={history}
          historyUser={historyUserBet}
          setPageUser={setPageUser}
        />
        <Dialog__NoticeWin
          data={dataModalWin}
          dialog={modalWin}
          toggle={toggleModalWin}
          isXocdia
        />
      </div>
    </>
  );
}

export default Xocdia;
