import "./table_result.css";
import xxh1 from "../../assets/images/xx_history1.png";
import xxh2 from "../../assets/images/xx_history2.png";
import xxh3 from "../../assets/images/xx_history3.png";
import xxh4 from "../../assets/images/xx_history4.png";
import xxh5 from "../../assets/images/xx_history5.png";
import xxh6 from "../../assets/images/xx_history6.png";
import "../../pages/game/game.css";
import { convertPhien, format_time_table_taixiu } from "../../utils/function";
import Dialog__KenoResult from "../modal/Dialog_keno_result";
import { useState } from "react";
import dayjs from "dayjs";

// import NullData from "../common/null_data";

const xx_history = [xxh1, xxh2, xxh3, xxh4, xxh5, xxh6];

const Table_keno = ({ data, phien, created }) => {
  // console.log(data);
  // const [dataDialog, setDataDialog] = useState(data?.[1]);
  const [dialog, setDialog] = useState(false);
  const handleClick = (data) => {
    // setDataDialog(data);
    setDialog(true);
  };
  // console.log(data);
  return (
    <div className="wrap-table-taixiu">
      <table className="table-taixiu text-[1.4rem] md:text-[1.3rem] sm:text-[1.2rem] relative">
        <thead>
          <tr className="bg-[#f56464]">
            <th className="w-[30%]">Phiên số</th>
            <th className="w-[40%]">Kết quả</th>

            <th className="w-[30%]">Thời gian</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-[30%]">{convertPhien(phien)}</td>

            <td className="w-[40%]">Đang chờ kết quả</td>

            <td className="w-[30%]">
              {dayjs(created).format("YYYY/MM/DD HH:mm")}
            </td>
          </tr>
          {data?.map((item, index) => (
            <tr key={index} onClick={() => handleClick(item)}>
              <td className="w-[30%]">{convertPhien(item.phien)}</td>
              <td className="flex justify-center font-bold">
                {item.ketqua.split(",").map((number, i) => {
                  return (
                    <span
                      key={i}
                      className="bg-[red] w-[2.5rem] h-[2.5rem] rounded-full text-white leading-[2.5rem] md:w-[2.1rem] md:h-[2.1rem] sm:leading-[1.9rem] sm:w-[1.9rem] sm:h-[1.9rem] md:leading-[2.1rem] mx-[1px]"
                    >
                      {number}
                    </span>
                  );
                })}
              </td>
              <td className="w-[30%]">
                {dayjs(item.updated_at).format("YYYY/MM/DD HH:mm")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <Dialog__KenoResult
        data={dataDialog}
        dialog={dialog}
        toggle={() => setDialog(!dialog)}
      /> */}
    </div>
  );
};

export default Table_keno;
