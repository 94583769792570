import { Link } from "react-router-dom";
import "./menu_item_content_3.css";

function Menu_item_content_3(obj) {
  return (
    <div className="flex flex-col mt-4">
      {/* <div className="text-left title-listgame relative pl-4">Danh sách</div> */}
      <div className="box-item-content">
        {obj.map((item, i) => {
          return (
            <Link to={item.path} key={i} className="item-little">
              <img className="item-img" src={item.img} />
              <span className="item-title">{item.title}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Menu_item_content_3;
