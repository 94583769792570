import { Box, CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetUsersInfoQuery } from "../../../redux_toolkit/apiUser";
import { toast } from "react-toastify";

import { useAddSavingMutation } from "../../../api/savingBoxApi";
import Loading from "../../../components/common/loading";
const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  "max-width": "450px",
  bgcolor: "background.paper",
  //   border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "7px",
};

const ModalNapKet = ({ open, setOpen, type, refetchDetail }) => {
  const [amount, setAmount] = useState(0);

  const { data: dataUserInfo, refetch: refetchUserInfo } =
    useGetUsersInfoQuery();

  const [addSaveBox, { data: res, isLoading }] = useAddSavingMutation();

  useEffect(() => {
    if (res) {
      if (res.status === true) {
        toast.success(res.msg);
        refetchUserInfo();
        refetchDetail();
        setOpen(false);
        setAmount(0);
      } else if (res.status === false) {
        toast.error(res.msg);
      }
    }
  }, [isLoading]);

  const handleChange = e => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    value = parseInt(value);
    setAmount(value || 0);
  };

  const handleConfirm = () => {
    if (amount > dataUserInfo?.money) {
      toast.error("Số dư không đủ");
    }
    addSaveBox({ amount, type });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading && (
            <Box className="h-full absolute top-0 w-full flex justify-center items-center bg-black bg-opacity-50 z-[99999999] px-[1rem] ml-[-3.2rem] rounded-lg">
              <CircularProgress color="error" size={30} />
            </Box>
          )}

          <h3 className="text-center text-[25px] xs:text-[19px]">
            {type == "plus" ? "Chuyển tiền vào két" : "Rút tiền khỏi két"}
          </h3>
          <input
            type="text"
            value={amount?.toLocaleString()}
            onChange={e => handleChange(e)}
            placeholder="Nhập số tiền"
            className="w-full mt-10 rounded-2xl shadow-[0_5px_5px_#d0d0ed5c] outline-none text-[20px] xs:text-[17px] xs:p-3 xs:px-5 p-5 px-10 border-none focus:outline-red-500 focus:outline-[.01333rem]"
          />
          <button
            onClick={handleConfirm}
            className="text-center text-[24px] xs:text-[18px] p-5 rounded-full text-white bg-gradient-to-r from-red-300 to-red-500 mt-10 w-full font-bold"
          >
            Xác nhận
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default ModalNapKet;
