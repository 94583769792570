import React from "react";
import "./common.css";
import { format_money } from "../../utils/function";

const BankBox2 = ({ userinfo }) => {
  return (
    <div className="bank-box-2">
      <span className="text-[1.7rem]">Số dư khả dụng</span>
      <span className="text-[2.3rem] font-bold">
        {format_money(userinfo.money)}
      </span>
      <div className="flex mt-4 absolute bottom-10 left-16 justify-between w-[75vw] max-w-[450px]">
        <div className="bank-img mb-4"></div>
        <span className="text-3xl">{userinfo.username}</span>
      </div>
    </div>
  );
};

export default BankBox2;
