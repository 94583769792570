import { Link, Outlet } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
import "../Layout.css";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdCardGiftcard } from "react-icons/md";
import { FaRegCircleUser } from "react-icons/fa6";
import { TbHomePlus } from "react-icons/tb";
import { useGetSettingQuery } from "../../../redux_toolkit/apiUser";

function nav(path, name, icon) {
  this.path = path;
  this.name = name;
  this.icon = icon;
}

const list_nav = [
  new nav("/promotion", "Khuyến mãi", MdCardGiftcard),
  new nav("/service", "Nạp tiền", TbHomePlus),
  new nav("/", "Trang chủ", AiOutlineHome),
  new nav("/profile", "Cá nhân", FaRegCircleUser),
  new nav("/service", "CSKH", BiSupport),
];

function Navbar(props) {
  let link = props.link;
  let [nav, setNav] = useState(link);

  useEffect(() => {
    setNav(link);
  }, [link]);

  const { data, isLoading } = useGetSettingQuery();

  return (
    <nav className="nav_wrapper">
      {list_nav.map((item) => {
        return (
          <Link
            to={item.path === "/service" ? data?.data?.link_cskh : item.path}
            className="nav-link"
            key={item.path}
            target={item.path === "/service" ? "_blank" : "_self"}
          >
            <div className="nav-item">
              {
                <item.icon
                  className={
                    item.path === "/"
                      ? "nav-icon nav-icon-center"
                      : nav === item.path
                      ? "nav-icon nav-active"
                      : "nav-icon"
                  }
                />
              }
              {item.path === "/" && <div className="nav-item-center-bg"></div>}
              <div
                className={
                  nav === item.path
                    ? "nav-title md:text-[1.2rem] sm:text-[1.1rem] nav-active"
                    : "nav-title md:text-[1.2rem] sm:text-[1.1rem]"
                }
              >
                {item.name}
              </div>
            </div>
          </Link>
        );
      })}

      <Outlet />
    </nav>
  );
}

export default memo(Navbar);
