import { format_money } from "../../../../utils/function";
import { HiQuestionMarkCircle } from "react-icons/hi";
import Dialog__Howplay from "../../../modal/Dialog_cachchoi";
import { limitStringLength } from "../../../../utils/function";
import { GrCaretNext } from "react-icons/gr";
import { useState } from "react";
import { tab } from "@material-tailwind/react";
import { InputGroupStyle } from "../../../input/input";
const nhapnhanh = ["1", "5", "10", "20", "50", "100"];
function Confirm_bet({
  props,
  pointBet,
  handleChange,
  handleClick,
  countSelected,
  tab1,
  tab2,
  handleReset,
  numberSelected,
  setNumberSelected,
  handleNhapnhanh,
  tilecuoc,
  tiletrathuong,
  game,
  type,
}) {
  const [dialogHowPlay, setDialogHowPlay] = useState(false);

  const a = limitStringLength(numberSelected.join(","), 150);
  if (game === "Đề 4 càng đặc biệt") {
    return (
      <>
        <div className="confirm-bet text-[1.5rem] md:text-[1.3rem] sm:text-[1.2rem] shadow">
          <span className="ml-[5%] text-left block mb-4">
            Số tiền cho 1 con
          </span>

          <input
            placeholder={game === "Lô" ? "Nhập số điểm" : "Nhập số tiền"}
            type="text"
            required
            maxLength={15}
            value={Number(pointBet).toLocaleString()}
            onChange={handleChange}
          />
          <span className="ml-[5%] text-left block mb-4">Chọn số</span>

          <input
            placeholder="Nhập số"
            type="text"
            required
            maxLength={4}
            min={"1"}
            value={numberSelected}
            onChange={e =>
              setNumberSelected([e.target.value.replace(/\D/g, "")])
            }
          />

          <div className="flex">
            <div className="ml-[5%] text-left flex">
              <span className="mr-2">Đã chọn:</span>
              <span className="mr-2 text-red-500">{countSelected} số</span>
            </div>
            <div className="text-left flex">
              <span className="mr-4">Tổng tiền cược:</span>
              <span className="mr-4 text-red-500">
                {format_money(pointBet * tilecuoc[props.code])}
              </span>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="w-full ml-[5%]">
              {props?.rate && (
                <div className="text-left flex">
                  <span className="mr-4">Tỉ lệ:</span>
                  <span className="mr-4 ">{`1 : ${
                    tiletrathuong[props.code]
                  }`}</span>
                </div>
              )}
            </div>
          </div>
          <button className="submit-btn" onClick={handleClick}>
            Đặt lệnh
          </button>
        </div>

        <Dialog__Howplay
          dialog={dialogHowPlay}
          toggle={() => setDialogHowPlay(!dialogHowPlay)}
          text={props.c}
          tab1={tab1}
          tab2={tab2}
        />
      </>
    );
  }
  return (
    <>
      <div className="confirm-bet text-[1.5rem] md:text-[1.3rem] sm:text-[1.2rem] shadow">
        <span className="ml-[5%] text-left block mb-4">
          {game === "Lô"
            ? `Nhập số điểm (1 điểm = ${format_money(
                (type.includes("xsmn") || type.includes("xsmt") ? 18 : 27) *
                  1000,
              )})`
            : "Số tiền cược"}
          {/* {`Nhập số điểm (1điểm = ${format_money(props.bet * 1000)})`} */}
        </span>

        <input
          placeholder={game === "Lô" ? "Nhập số điểm" : "Nhập số tiền"}
          type="tel"
          maxLength={15}
          required
          min={"1"}
          value={Number(pointBet).toLocaleString()}
          onChange={handleChange}
        />
        {/* <div className="flex flex-col">
          <span className="ml-[5%] text-left">Nhập nhanh:</span>
          <div className="flex ml-[5%]">
            {nhapnhanh.map((item, i) => {
              return (
                <span
                  key={i}
                  className={`w-14 rounded bg-blue-200 text-[1.2rem] mx-2 py-2 ${
                    pointBet === item ? "active" : ""
                  }`}
                  onClick={() => handleNhapnhanh(item)}
                >
                  {item}
                </span>
              );
            })}
          </div>
        </div> */}

        {/* <div>
          {props.count && (
            <div className="ml-[5%] flex">
              <span className="mr-2">Chọn</span>
              <span className="mr-2 text-red-500">{props.count}</span>
              <span className="mr-4">số</span>
            </div>
          )}
        </div> */}

        <div className="flex">
          <div className="ml-[5%] text-left flex">
            <span className="mr-2">Đã chọn:</span>
            <span className="mr-2 text-red-500">{countSelected} số</span>
          </div>
          <div className="text-left flex">
            <span className="mr-4">Tổng tiền cược:</span>
            <span className="mr-4 text-red-500">
              {game === "Lô"
                ? `${format_money(
                    countSelected *
                      pointBet *
                      1000 *
                      (type.includes("xsmn") || type.includes("xsmt")
                        ? 18
                        : 27),
                  )}`
                : `${format_money(
                    countSelected * pointBet * tilecuoc[props.code],
                  )}`}
            </span>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="w-full ml-[5%]">
            {/* {props.rate && (
              <div className="text-left flex w-full">
                <span className="mr-4">Thắng cược lên tới:</span>
                <span className="mr-4 text-red-500">
                  {format_money(
                    Math.round(countSelected * props.rate * pointBet * 1000)
                  )}
                </span>
              </div>
            )} */}
            {props?.rate && (
              <div className="text-left flex">
                <span className="mr-4">Tỉ lệ:</span>
                <span className="mr-4 ">{`1 : ${
                  tiletrathuong[props.code]
                }`}</span>
              </div>
            )}
            {/* {props?.rate1 && (
              <div className="text-left flex">
                <span className="mr-4">Tỉ lệ kèo đôi:</span>
                <span className="mr-4 text-red-500">{props.rate1}</span>
              </div>
            )}
            {props?.rate2 && (
              <div className="text-left flex">
                <span className="mr-4">Tỉ lệ kèo số:</span>
                <span className="mr-4 text-red-500">{props.rate2}</span>
              </div>
            )} */}
          </div>
        </div>
        <button className="submit-btn" onClick={handleClick}>
          Đặt lệnh
        </button>
      </div>
      {/* 
      <div className="show-num">
        <div className="mx-[5%] flex items-center mb-6 border-b border-slate-300 text-green-400 font-semibold justify-between pb-2">
          <div className="flex items-center">
            <span className="mx-2">{tab1}</span>
            <GrCaretNext /> <span className="ml-2">{tab2}</span>
          </div>
          <button
            className="flex items-center bg-blue-gray-100 text-[red] font-thin italic px-3 py-1 rounded-full shadow"
            onClick={() => setDialogHowPlay(!dialogHowPlay)}
          >
            <HiQuestionMarkCircle />
            <span>Cách chơi</span>
          </button>
        </div>
        <div className="ml-[5%] flex items-center mb-4">
          <button className="reset-btn" onClick={handleReset}>
            Đặt lại
          </button>
          <div className="ml-[5%] text-left flex">
            <span className="mr-2">Đã chọn:</span>{" "}
            <span className="mr-2 text-red-500">{countSelected}</span>
          </div>
        </div>
        <div className="num-selected">{a}</div>
      </div> */}
      <Dialog__Howplay
        dialog={dialogHowPlay}
        toggle={() => setDialogHowPlay(!dialogHowPlay)}
        text={props.c}
        tab1={tab1}
        tab2={tab2}
      />
    </>
  );
}

export default Confirm_bet;
