import React from "react";
import "./common.css";

const BankBox = ({ number, name, bank, style }) => {
  // console.log(userinfo);

  return (
    <div className="bank-box overflow-hidden" style={style}>
      <span className="md:text-[1.4rem] sm:text-[1.2rem]">{number}</span>
      <span className="text-[2rem] md:text-[1.8rem] sm:text-[1.6rem]">
        {name}
      </span>
      <div className="flex mt-4 absolute bottom-14 left-16 item">
        <div className="bank-img mr-4"></div>
        <span className="md:text-[1.7rem] sm:text-[1.5rem]">{bank}</span>
      </div>
    </div>
  );
};

export default BankBox;
