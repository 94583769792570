import { useEffect, useState } from "react";
import xx1 from "../../../assets/images/xx1.png";
import xx2 from "../../../assets/images/xx2.png";
import xx3 from "../../../assets/images/xx3.png";
import xx4 from "../../../assets/images/xx4.png";
import xx5 from "../../../assets/images/xx5.png";
import xx6 from "../../../assets/images/xx6.png";

const background_result = [xx1, xx2, xx3, xx4, xx5, xx6];

function Xucxac_spin({ message, time, taixiu = false }) {
  const [results, setResults] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      const randomArray = Array.from({ length: 3 }, () =>
        Math.floor(Math.random() * 6)
      );
      setResults(randomArray);
    }, 100);

    return () => clearInterval(interval);
  }, []);
  // console.log(message);
  return (
    <div className="xucxac-spin-box">
      <div className="box-quay">
        <div className="box">
          {time > 5 &&
            !taixiu &&
            message.lastResultXucSac?.ketqua.split(",").map((item) => {
              return (
                <div>
                  <img
                    alt="kết quả xúc xắc"
                    className="results md:w-[80%!important] sm:w-[85%!important]"
                    src={background_result[item - 1]}
                  ></img>
                </div>
              );
            })}
          {time > 5 &&
            taixiu &&
            message.lastResultSicbo?.ketqua.split(",").map((item) => {
              return (
                <div>
                  <img
                    alt="kết quả xúc xắc"
                    className="results md:w-[80%!important] sm:w-[85%!important]"
                    src={background_result[item - 1]}
                  ></img>
                </div>
              );
            })}
          {!(time > 5) &&
            results.map((number) => {
              return (
                <div>
                  <img
                    alt="kết quả xúc xắc"
                    className="results md:w-[80%!important] sm:w-[85%!important]"
                    src={background_result[number]}
                  ></img>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Xucxac_spin;
