import React from "react";
import { FaBell } from "react-icons/fa";
import { useGetNotificationHomeQuery } from "../../../../redux_toolkit/apiUser";
import { IoIosVolumeHigh } from "react-icons/io";

function SliderNotification() {
  const { data, isLoading } = useGetNotificationHomeQuery();
  // console.log(data);
  return (
    <div className="textAnimate">
      <div className="w-[7rem] flex justify-center items-center">
        <IoIosVolumeHigh
          className="md:w-[5rem] sm:w-[3rem]"
          size={22}
          color="red"
        />
      </div>
      <div className="wrapper">
        {!isLoading && <p className="target">{data?.data?.title}</p>}
      </div>
    </div>
  );
}

export default SliderNotification;
