import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkToken } from "../utils/function";

const domain = process.env.REACT_APP_DOMAIN;

export const apiAdmin = createApi({
  reducerPath: "apiAdmin",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/portal" }),

  endpoints: (builder) => ({
    getProgramAgency: builder.query({
      query: () => ({
        url: `/agency/program`,
        headers: headers(),
        method: "GET",
      }),
    }),
    registerAgency: builder.mutation({
      query: (params) => ({
        url: `/agency/list`,
        headers: headers(),
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetProgramAgencyQuery, useRegisterAgencyMutation } = apiAdmin;
