import { useState, useEffect } from "react";
import "./taixiu_bet.css";
import { IoIosWarning } from "react-icons/io";
import Dialog__TimeOut from "../../../../../components/modal/Dialog_time_out";
import Dialog__NotEnoughMoney from "../../../../../components/modal/Dialog_not_enough_money";
import Dialog_confirm_info_bet from "../../../../../components/modal/Dialog_confirm_info_bet";
import Dialog__SuccessBet from "../../../../../components/modal/Dialog_success_bet";
import Dialog__NotLogin from "../../../../../components/modal/Dialog_Not_login";
import {
  format_money_no_monetary_unit,
  format_money,
} from "../../../../../utils/function";
import Input from "../../../../../components/common/input";
import isEmpty from "validator/lib/isEmpty";
import { toast } from "react-toastify";
import { cltx } from "../../../../../utils/const";

// function option(name, rate, code) {
//   this.name = name;
//   this.rate = rate;
//   this.code = code;
// }

// const option_bet = [
//   new option("Chẵn", 1.9, "chan"),
//   new option("Lẻ", 1.9, "le"),
//   new option("Tài", 1.9, "tai"),
//   new option("Xỉu", 1.9, "xiu"),
// ];

const betAmount = [
  "100000",
  "500000",
  "1000000",
  "2000000",
  "5000000",
  "10000000",
  "20000000",
  "50000000",
];

function Taixiu_bet({ message, sendJsonMessage, dataUser, type, time, tile }) {
  // console.log(messageNotice);
  const [optionSelected, setOptionSelected] = useState([]);
  const [amountSuggest, setAmountSuggest] = useState("");
  const [pointBet, setPointBet] = useState("");
  const [dialogTimeout, setDialogTimeout] = useState(false);
  const [dialogNotEnoughMoney, setDialogNotEnoughMoney] = useState(false);
  const [dialogConfirmBet, setDialogConfirmBet] = useState(false);
  const [dialogSuccessBet, setDialogSuccessBet] = useState(false);
  const [dialogNotLogin, setDialogNotLogin] = useState(false);

  const handleClickAmount = (number) => {
    setAmountSuggest(number);
    setPointBet(number);
  };

  const handleChange = (event) => {
    setPointBet(event.target.value.replace(/\D/g, ""));
  };

  const toggle_SuccessBet = () => {
    setDialogSuccessBet(!dialogSuccessBet);
    setPointBet("");
    setOptionSelected("");
    setAmountSuggest("");
  };

  const handleReset = () => {
    setPointBet("");
    setAmountSuggest("");
  };

  const handleMulti = (number) => {
    const x = parseInt(pointBet);
    if (x > 0) {
      setPointBet((x * number).toString());
    }
  };
  const handleOption = (option) => {
    if (optionSelected.includes(option)) {
      setOptionSelected(optionSelected.filter((item) => item !== option));
    } else {
      setOptionSelected([...optionSelected, option]);
    }
  };

  // const handleConfirm = () => {
  //   if (time < 6) {
  //     setDialogTimeout(!dialogTimeout);
  //   } else if (time > 5) {
  //     sendJsonMessage({
  //       taixiu: {
  //         cuoc: {
  //           theloai: type,
  //           cuadat: optionSelected,
  //           sotien: pointBet,
  //         },
  //       },
  //     });
  //   } else {
  //     toast.warn("Thao tác quá nhanh!!!");
  //   }
  //   setDialogConfirmBet(!dialogConfirmBet);
  // };

  const handleClickBet = () => {
    if (!dataUser || dataUser?.status === "error") {
      setDialogNotLogin(true);
      return;
    } else if (isEmpty(pointBet)) {
      toast.warn("Chưa nhập số tiền cược");
      return;
    } else if (optionSelected.length === 0) {
      toast.warn("Chưa chọn cửa đặt");
      return;
    } else if (pointBet < 1000) {
      toast.warn("Tiền cược tối thiểu là 1.000");
      return;
    } else if (pointBet > dataUser.money) {
      setDialogNotEnoughMoney(!dialogNotEnoughMoney);
      return;
    } else if (time < 6) {
      setDialogTimeout(!dialogTimeout);
      return;
    } else {
      optionSelected.map((item) => {
        sendJsonMessage({
          taixiu: {
            cuoc: {
              theloai: type,
              cuadat: item,
              sotien: pointBet,
            },
          },
        });
      });
      return;
    }
  };

  useEffect(() => {
    if (message?.notice) {
      if (message.notice.msg === "Đặt cược thành công!") {
        setDialogSuccessBet(true);
      } else if (message.notice.msg === "Đăng nhập thành công") {
        return;
      } else if (message.notice.msg === "Bạn đã bị khoá cược!") {
        toast.error("Bạn đã bị khóa cược");
        return;
      } else if (message.notice.msg === "Tài khoản đã bị khóa!") {
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        toast.error("Tài khoản đã bị khóa");
        setTimeout(() => {
          window.location.assign("/login");
        }, 2000);
      }
    }
  }, [message?.notice?.msg]);

  return (
    <>
      <div className="option-taixiu">
        {cltx.map((item) => {
          return (
            <div
              onClick={() => handleOption(item.code)}
              className={
                optionSelected.includes(item.code)
                  ? "option-item-taixiu active"
                  : "option-item-taixiu"
              }
            >
              <span
                className={
                  optionSelected.includes(item.code)
                    ? "text-white font-bold"
                    : "text-red-500 font-bold"
                }
              >
                {item.name}
              </span>
              <span
                className={
                  optionSelected.includes(item.code)
                    ? "text-white font-bold"
                    : "text-gray-400"
                }
              >
                {tile[item.code]}
              </span>
            </div>
          );
        })}
      </div>
      <div className="taixiu-betbox">
        <span className="text-[2.3rem] font-bold md:text-[2.2rem] sm:text-[2rem]">
          Chọn mức cược
        </span>
        <div className="taixiu-suggested-amount md:text-[1.3rem] sm:text-[1.2rem]">
          {betAmount.map((money) => {
            return (
              <span
                onClick={() => handleClickAmount(money)}
                className={amountSuggest == money ? "active" : ""}
              >
                {format_money_no_monetary_unit(money)}
              </span>
            );
          })}
          {/* <span
            className="bg-pink-300 p-2 rounded-lg shadow mr-2 text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]"
            onClick={() => handleMulti(2)}
          >
            x2
          </span>
          <span
            className="bg-pink-300 p-2 rounded-lg shadow mr-2text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]"
            onClick={() => handleMulti(5)}
          >
            x5
          </span>
          <span
            className="bg-pink-300 p-2 rounded-lg shadow mr-2 text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]"
            onClick={() => handleReset()}
          >
            Reset
          </span> */}
        </div>
        <div className="w-[90%] m-auto md:text-[1.4rem] sm:text-[1.3rem]">
          <Input
            placeholder="Nhập số tiền cược"
            onChange={handleChange}
            value={Number(pointBet).toLocaleString()}
            maxLength={15}
            type="text"
          />
        </div>

        <div className="md:text-[1.4rem] sm:text-[1.3rem]">
          <span className="text-white mr-2">Tổng tiền cược:</span>
          <span className="text-[red]">{format_money(pointBet)}</span>
        </div>
        <button onClick={handleClickBet}>Đặt cược</button>
      </div>
      <Dialog__NotEnoughMoney
        dialog={dialogNotEnoughMoney}
        toggle={() => setDialogNotEnoughMoney(!dialogNotEnoughMoney)}
      />
      <Dialog__TimeOut
        dialog={dialogTimeout}
        toggle={() => setDialogTimeout(!dialogTimeout)}
      />
      {/* <Dialog_confirm_info_bet
        dialog={dialogConfirmBet}
        optionSelected={optionSelected}
        point={pointBet}
        cuadat={optionSelected}
        toggle1={handleConfirm}
        toggle2={() => setDialogConfirmBet(!dialogConfirmBet)}
      /> */}
      <Dialog__SuccessBet
        dialog={dialogSuccessBet}
        toggle={toggle_SuccessBet}
      />
      <Dialog__NotLogin
        dialog={dialogNotLogin}
        toggle={() => setDialogNotLogin(!dialogNotLogin)}
      />
    </>
  );
}

export default Taixiu_bet;
