import {
  MdNavigateNext,
  MdHistory,
  MdOutlineCurrencyExchange,
  MdAttachMoney,
} from "react-icons/md";
import { MdCreditScore } from "react-icons/md";
import { RiBankFill } from "react-icons/ri";
import SvgIcon from "@mui/material/SvgIcon";
import { VscSearchFuzzy } from "react-icons/vsc";

// import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { FaPeopleGroup } from "react-icons/fa6";

import { FaPiggyBank, FaMoneyCheck, FaUserFriends } from "react-icons/fa";
import { BsBank } from "react-icons/bs";
import { PiPasswordFill } from "react-icons/pi";
import { ImExit } from "react-icons/im";

import { item_box_1, item_box_2, item_box_3, item_4, item_5 } from "./function";
import {
  xo_so_3_mien,
  xo_so_nhanh,
  xoc_dia,
  xuc_sac,
  ke_no,
  tai_xiu,
  khuyen_mai,
  xo_so_mien_bac,
  xo_so_mien_trung,
  xo_so_mien_nam,
  xo_so_nhanh_3p,
  xo_so_nhanh_5p,
  xoc_dia_3p,
  xoc_dia_5p,
  xuc_xac_3p,
  xuc_xac_5p,
  keno_1p,
  keno_3p,
  keno_5p,
  tai_xiu_1p,
  tai_xiu_3p,
  tai_xiu_5p,
} from "../pages/images";
import { keno_logo } from "../assets/images/des1";

export const headers = () => {
  return {
    "x-access-token": localStorage.getItem("auth"),
    "Access-Control-Allow-Origin": "*",
  };
};

function option(name, rate, code) {
  this.name = name;
  this.rate = rate;
  this.code = code;
}

function option2(name, rate, code, count) {
  this.name = name;
  this.rate = rate;
  this.code = code;
  this.count = count;
}

export const xs3m = [
  new item_box_3("/xoso/xsmb", xo_so_mien_bac),
  new item_box_3("/xsmt", xo_so_mien_trung),
  new item_box_3("/xsmn", xo_so_mien_nam),
];

export const xsn = [
  // new item_box_1(
  //   "Xổ số nhanh 45s",
  //   "Thể loại: Dự đoán",
  //   "Dự đoán xổ số để dành chiến thắng",
  //   xo_so_nhanh_3p,
  //   "/xosonhanh/45"
  // ),
  // new item_box_1(
  //   "Xổ số nhanh 75s",
  //   "Thể loại: Dự đoán",
  //   "Dự đoán xổ số để dành chiến thắng",
  //   xo_so_nhanh_5p,
  //   "xosonhanh/75"
  // ),
  // new item_box_1(
  //   "Xổ số nhanh 2P",
  //   "Thể loại: Dự đoán",
  //   "Dự đoán xổ số để dành chiến thắng",
  //   xo_so_nhanh_3p,
  //   "/xosonhanh/120"
  // ),
  new item_box_1(
    "Xổ số nhanh 3P",
    "Thể loại: Dự đoán",
    "Dự đoán xổ số để dành chiến thắng",
    xo_so_nhanh_5p,
    "/xosonhanh/180"
  ),
  new item_box_1(
    "Xổ số nhanh 5P",
    "Thể loại: Dự đoán",
    "Dự đoán xổ số để dành chiến thắng",
    xo_so_nhanh_3p,
    "/xosonhanh/300"
  ),
];

export const xd = [
  new item_box_1(
    "Xóc đĩa 3P",
    "Đoán số",
    "Lớn/Nhỏ/Chẵn/Lẻ để giành chiến thắng",
    xoc_dia_3p,
    "/xocdia/3"
  ),
  new item_box_1(
    "Xóc đĩa 5P",
    "Đoán số",
    "Lớn/Nhỏ/Chẵn/Lẻ để giành chiến thắng",
    xoc_dia_5p,
    "/xocdia/5"
  ),
];

export const xx = [
  // new item_box_1(
  //   "Đổ xúc xắc 20s",
  //   "Thể loại: Dự đoán",
  //   "Dự đoán xúc sắc để giành chiến thắng",
  //   xuc_xac_3p,
  //   "/xucxac/20"
  // ),
  // new item_box_1(
  //   "Đổ xúc xắc 30s",
  //   "Thể loại: Dự đoán",
  //   "Dự đoán xúc sắc để giành chiến thắng",
  //   xuc_xac_5p,
  //   "/xucxac/30"
  // ),
  // new item_box_1(
  //   "Đổ xúc xắc 40s",
  //   "Thể loại: Dự đoán",
  //   "Dự đoán xúc sắc để giành chiến thắng",
  //   xuc_xac_3p,
  //   "/xucxac/40"
  // ),
  // new item_box_1(
  //   "Đổ xúc xắc 50s",
  //   "Thể loại: Dự đoán",
  //   "Dự đoán xúc sắc để giành chiến thắng",
  //   xuc_xac_5p,
  //   "/xucxac/50"
  // ),
  new item_box_1(
    "Đổ xúc xắc 3P",
    "Dự đoán",
    "Dự đoán Xúc sắc để giành chiến thắng",
    xuc_xac_3p,
    "/xucxac/180"
  ),
  new item_box_1(
    "Đổ xúc xắc 5P",
    "Dự đoán",
    "Dự đoán Xúc sắc để giành chiến thắng",
    xuc_xac_5p,
    "/xucxac/300"
  ),
];

export const kn = [
  new item_box_2("KENO 1P", keno_logo, "/keno/60"),
  new item_box_2("KENO 3P", keno_logo, "/keno/180"),
  new item_box_2("KENO 5P", keno_logo, "/keno/300"),
  // new item_box_2("Keno 50s", keno_1p, "/keno/50"),
  // new item_box_2("Keno 60s", keno_1p, "/keno/60"),
  // new item_box_1(
  //   "Keno 5P",
  //   "Đoán số",
  //   "Lớn/Nhỏ/Lẻ/Chẵn để giành chiến thắng",
  //   keno_logo,
  //   "/keno/300"
  // ),
  // new item_box_1(
  //   "Keno 3P",
  //   "Đoán số",
  //   "Lớn/Nhỏ/Lẻ/Chẵn để giành chiến thắng",
  //   keno_logo,
  //   "/keno/180"
  // ),
  // new item_box_1(
  //   "Keno 1P",
  //   "Đoán số",
  //   "Lớn/Nhỏ/Lẻ/Chẵn để giành chiến thắng",
  //   keno_logo,
  //   "/keno/60"
  // ),
];

export const tx = [
  new item_box_2("TÀI XỈU 1P", tai_xiu_1p, "/taixiu/60"),
  new item_box_2("TÀI XỈU 3P", tai_xiu_1p, "/taixiu/180"),
  new item_box_2("TÀI XỈU 5P", tai_xiu_1p, "/taixiu/300"),
];

export const list_profile = [
  new item_4(
    "Lịch sử tham gia",
    <VscSearchFuzzy className="w-10 h-10" />,
    "/historyplay"
  ),
  new item_4(
    "Biến động số dư",
    <FaMoneyCheck className="w-10 h-10" />,
    "/history"
  ),
  new item_4("Két tiết kiệm", <FaPiggyBank className="w-10 h-10" />, "/money"),
  new item_4(
    "Lịch sử nạp",
    <MdCreditScore className="w-10 h-10" />,
    "/recharge_record"
  ),
  new item_4(
    "Lịch sử rút",
    <MdAttachMoney className="w-10 h-10" />,
    "/withdraw_record"
  ),
  new item_4(
    "Liên kết ngân hàng",
    <RiBankFill className="w-10 h-10" />,
    "/addbank"
  ),
  // new item_4("Mời bạn bè", <FaUserFriends className="w-10 h-10" />, "/invite"),
  new item_4("Đại lý", <FaPeopleGroup className="w-10 h-10" />, "/agency"),
  new item_4(
    "Đổi mật khẩu",
    <PiPasswordFill className="w-10 h-10" />,
    "/password"
  ),
  new item_4("Đăng xuất", <ImExit className="w-10 h-10" />, "/login", true),
];

export const xo_so = {
  lo2: "Lô 2 số",
  lo2sodau: "Lô 2 số đầu",
  lo2so1k: "Lô 2 số 1k",
  lo3so: "Lô 3 số",
  lo4so: "Lô 4 số",
  xien2: "Xiên 2",
  xien3: "Xiên 3",
  xien4: "Xiên 4",
  de: "Đề",
  daude: "Đầu đề",
  de7: "Đề giải 7",
  de1: "Đề giải nhất",
  daude1: "Đầu đề giải nhất",
  "3cang": "Ba càng",
  "3cangg1": "Ba càng giải nhất",
  cang4: "Bốn càng đặc biệt",
  dau: "Đầu",
  duoi: "Đuôi",
  truotxien4: "Trượt xiên 4",
  truotxien8: "Trượt xiên 8",
  truotxien10: "Trượt xiên 10",
  ds_tong: "Tổng (Đặc sắc)",
  ds_sodau: "Số đầu (Đặc sắc)",
  ds_sohai: "Số hai (Đặc sắc)",
  ds_soba: "Số ba (Đặc sắc)",
  ds_sobon: "Số bốn (Đặc sắc)",
  ds_sonam: "Số năm (Đặc sắc)",
  thuvi: "Lô 2 số giải ĐB (Thú vị)",
};

export const keo_doi = {
  tai: "Tài",
  xiu: "Xỉu",
  chan: "Chẵn",
  le: "Lẻ",
};

export const historyplay_game = [
  new item_5("Xổ số", "lottery", "all"),
  new item_5("Xóc đĩa", "xocdia", 300),
  new item_5("Xúc xắc", "xucsac", 180),
  new item_5("Keno", "keno5numbers", 30),
  new item_5("Tài xỉu", "sicbo", 30),
];

export const user_system_to_VNese = {
  user: "Người chơi",
  system: " Hệ thống",
};

export const Xocdia_Cuadat = {
  le: "Lẻ",
  chan: "Chẵn",
  "3trang1do": "3 trắng 1 đỏ",
  "3do1trang": "3 đỏ 1 trắng",
  "4trang": "4 trắng",
  "4do": "4 đỏ",
};

// export const xoso_code_to_region = {
//   xsmb: "XSMB",
//   "xsmt-xspy": "MT-Phú Yên",
//   "xsmt-xstth": "MT-Thừa Thiên Huế",
//   "xsmt-xsdlk": "MT-Đắk Lắk",
//   "xsmt-xsqnm-xsqna": "MT-Quảng Nam",
//   "xsmt-xsdng-xsdna": "MT-Đà Nẵng",
//   "xsmt-xskh": "MT-Khánh Hòa",
//   "xsmt-xsbdi": "MT-Bình Định",
//   "xsmt-xsqb": "MT-Quảng Bình",
//   "xsmt-xsqt": "MT-Quảng Trị",
//   "xsmt-xsgl": "MT-Gia Lai",
//   "xsmt-xsnt": "MT-Ninh THuận",
//   "xsmt-xsdn": "MT-Đắk Nông",
//   "xsmt-xsqng": "MT-Quảng Ngãi",
//   "xsmt-xskt": "MT-Kon Tum",
//   ""
// };

export const list_xucxac = {
  tai: "T",
  xiu: "X",
  chan: "C",
  le: "L",
  bon: "4",
  nam: "5",
  sau: "6",
  bay: "7",
  tam: "8",
  chin: "9",
  muoi: "10",
  muoi1: "11",
  muoi2: "12",
  muoi3: "13",
  muoi4: "14",
  muoi5: "15",
  muoi6: "16",
  muoi7: "17",
  number1: "Số đơn 1",
  number2: "Số đơn 2",
  number3: "Số đơn 3",
  number4: "Số đơn 4",
  number5: "Số đơn 5",
  number6: "Số đơn 6",
  number_two_any: "2 số giống nhau bất kỳ",
  number11: "2 số giống nhau 11",
  number22: "2 số giống nhau 22",
  number33: "2 số giống nhau 33",
  number44: "2 số giống nhau 44",
  number55: "2 số giống nhau 55",
  number66: "2 số giống nhau 66",
  number111: "3 số giống nhau 111",
  number222: "3 số giống nhau 222",
  number333: "3 số giống nhau 333",
  number444: "3 số giống nhau 444",
  number555: "3 số giống nhau 555",
  number666: "3 số giống nhau 666",
  number_three_any: "3 số giống nhau bất kỳ",
};

export const cltx = [
  new option("T", 1.985, "tai"),
  new option("X", 1.985, "xiu"),
  new option("L", 1.985, "le"),
  new option("C", 1.985, "chan"),
];
export const tong = [
  new option("4", 60, "bon"),
  new option("5", 30, "nam"),
  new option("6", 17, "sau"),
  new option("7", 12, "bay"),
  new option("8", 8, "tam"),
  new option("9", 6, "chin"),
  new option("10", 6, "muoi"),
  new option("11", 6, "muoi1"),
  new option("12", 6, "muoi2"),
  new option("13", 8, "muoi3"),
  new option("14", 12, "muoi4"),
  new option("15", 17, "muoi5"),
  new option("16", 30, "muoi6"),
  new option("17", 60, "muoi7"),
];

export const so_don = [
  new option("1", 2, "number1"),
  new option("2", 2, "number2"),
  new option("3", 2, "number3"),
  new option("4", 2, "number4"),
  new option("5", 2, "number5"),
  new option("6", 2, "number6"),
];
export const so_trung_2 = [
  new option("Trùng bất kỳ", 3, "number_two_any"),
  new option("11", 13, "number11"),
  new option("22", 13, "number22"),
  new option("33", 13, "number33"),
  new option("44", 13, "number44"),
  new option("55", 13, "number55"),
  new option("66", 13, "number66"),
];

export const so_trung_3 = [
  new option("Trùng bất kỳ", 33, "number_three_any"),
  new option("111", 200, "number111"),
  new option("222", 200, "number222"),
  new option("333", 200, "number333"),
  new option("444", 200, "number444"),
  new option("555", 200, "number555"),
  new option("666", 200, "number666"),
];

export const list_keno = {
  tai: "Tài",
  xiu: "Xỉu",
  chan: "Chẵn",
  le: "Lẻ",
  hoa: "Hòa",
  tren: "Trên",
  duoi: "Dưới",
  tai_le: "Tài lẻ",
  xiu_le: "Xỉu lẻ",
  tai_chan: "Tài chẵn",
  xiu_chan: "Xỉu chẵn",
  kim: "Kim",
  moc: "Mộc",
  thuy: "Thủy",
  nh_hoa: "Hỏa",
  tho: "Thổ",
};

export const trenduoi = [
  new option("Trên", 2.3, "tren"),
  new option("Hòa", 4.3, "hoa"),
  new option("Dưới", 2.3, "duoi"),
];
export const nguhanh = [
  new option("Kim", 9.2, "kim"),
  new option("Mộc", 4.6, "moc"),
  new option("Thủy", 2.4, "thuy"),
  new option("Hỏa", 4.6, "hoa"),
  new option("Thổ", 9.2, "tho"),
];
export const keodoi = [
  new option("Tài Lẻ", 3.7, "tai_le"),
  new option("Xỉu Lẻ", 3.7, "xiu_le"),
  new option("Tài Chẵn", 3.7, "tai_chan"),
  new option("Xỉu Chẵn", 3.7, "xiu_chan"),
];

export const cltx_kn = [
  new option("T", 1.999, "tai"),
  new option("X", 1.999, "xiu"),
  new option("L", 1.999, "le"),
  new option("C", 1.999, "chan"),
];

export const gameXoso = [
  new option2("Lô", 1.95, "lo2"),
  new option2("Lô xiên 2", 1.95, "xien2", 2),
  new option2("Lô xiên 3", 1.95, "xien3", 3),
  new option2("Lô xiên 4", 1.95, "xien4", 4),
  new option2("Đề", 1.95, "de"),
  new option2("Trượt xiên 4", 1.95, "truotxien4", 4),
  new option2("Trượt xiên 8", 1.95, "truotxien8", 8),
  new option2("Trượt xiên 10", 1.95, "truotxien10", 10),
  new option2("Lô 3 càng", 1.95, "3cang"),
  new option2("Đề 4 càng đặc biệt", 1.95, "cang4"),
];

export const list_keno5 = {
  taiTong: "T Tổng",
  xiuTong: "X Tổng",
  chanTong: "C Tổng",
  leTong: "L Tổng",
  tai1: "T Số thứ 1",
  xiu1: "X Số thứ 1",
  le1: "L Số thứ 1",
  chan1: "C Số thứ 1",
  tai2: "T Số thứ 2",
  xiu2: "X Số thứ 2",
  le2: "L Số thứ 2",
  chan2: "C Số thứ 2",
  tai3: "T Số thứ 3",
  xiu3: "X Số thứ 3",
  le3: "L Số thứ 3",
  chan3: "C Số thứ 3",
  tai4: "T Số thứ 4",
  xiu4: "X Số thứ 4",
  le4: "L Số thứ 4",
  chan4: "C Số thứ 5",
  tai5: "T Số thứ 5",
  xiu5: "X Số thứ 5",
  le5: "L Số thứ 5",
  chan5: "C Số thứ 5",
};
