import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkToken } from "../utils/function";

const domain = process.env.REACT_APP_DOMAIN;

export const apiUser = createApi({
  reducerPath: "apiUser",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/user" }),

  endpoints: (builder) => ({
    getUsersInfo: builder.query({
      query: () => ({
        url: `/userInfo`,
        headers: headers(),
        method: "GET",
      }),
    }),
    getResultsHistory: builder.query({
      query: ({ startDate, endDate, type, gameType, page }) => ({
        url: `/minigame/history/result?startDate=${startDate}&endDate=${endDate}&type=${type}&gameType=${gameType}&page=${page}`,
        method: "GET",
        headers: headers(),
      }),
      transformResponse: (res) => {
        checkToken(res);
        return res.data.data;
      },
    }),
    getHistoryBet: builder.query({
      query: ({ startDate, endDate, type, providercode, gameType, page }) => ({
        url: `/bet-history/?startDate=${startDate}&endDate=${endDate}&type=${type}&providercode=${providercode}&gameType=${gameType}&page=${page}`,
        method: "GET",
        headers: headers(),
      }),
      transformResponse: (res) => {
        checkToken(res);

        return res.data;
      },
    }),
    putPassword: builder.mutation({
      query: (params) => ({
        url: `/change/password`,
        headers: headers(),
        body: params,
        method: "PUT",
      }),
    }),
    getListUserBank: builder.query({
      query: () => ({
        url: `/list/bank`,
        headers: headers(),
        method: "GET",
      }),
    }),
    changeBanking: builder.mutation({
      query: (params) => ({
        url: `/addbanking`,
        headers: headers(),
        body: params,
        method: "POST",
      }),
    }),
    getFinancial: builder.query({
      query: ({ startDate, endDate, page, by, username }) => ({
        url: `/player/financial/details?startDate=${startDate}&endDate=${endDate}&page=${page}&by=${by}&username=${username}`,
        headers: headers(),
        method: "GET",
      }),
    }),
    getListAvatar: builder.query({
      query: () => ({
        url: `/avatar/list`,
        headers: headers(),
        method: "GET",
      }),
    }),
    changeAvatar: builder.mutation({
      query: (params) => ({
        url: `/avatar/change`,
        headers: headers(),
        body: params,
        method: "PUT",
      }),
    }),
    getPromotion: builder.query({
      query: () => ({
        url: `/getPromotion`,
        headers: headers(),
        method: "GET",
      }),
      transformResponse: (res) => {
        checkToken(res);
        return res.data;
      },
    }),
    getSetting: builder.query({
      query: () => ({
        url: `/setting`,
        headers: headers(),
        method: "GET",
      }),
      // transformResponse: (res) => {
      //   checkToken(res);
      //   return res.data;
      // },
    }),
    getNotificationHome: builder.query({
      query: () => ({
        url: `/notification/home`,
        headers: headers(),
        method: "GET",
      }),
    }),
    getRechargeHistory: builder.query({
      query: ({ username, page }) => ({
        url: `/deposit/history?username=${username}&page=${page}`,
        headers: headers(),
        method: "GET",
      }),
    }),
    getWithdrawHistory: builder.query({
      query: ({ username, page }) => ({
        url: `/withdraw/history?username=${username}&page=${page}`,
        headers: headers(),
        method: "GET",
      }),
    }),
    withdraw: builder.mutation({
      query: (params) => ({
        url: `/withdraw`,
        headers: headers(),
        body: params,
        method: "POST",
      }),
    }),
    changePasswordTransaction: builder.mutation({
      query: (params) => ({
        url: `/change/password-transaction`,
        headers: headers(),
        body: params,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetUsersInfoQuery,
  useGetResultsHistoryQuery,
  useGetHistoryBetQuery,
  usePutPasswordMutation,
  useGetListUserBankQuery,
  useChangeBankingMutation,
  useGetFinancialQuery,
  useGetListAvatarQuery,
  useChangeAvatarMutation,
  useGetPromotionQuery,
  useGetSettingQuery,
  useGetNotificationHomeQuery,
  useGetRechargeHistoryQuery,
  useGetWithdrawHistoryQuery,
  useWithdrawMutation,
  useChangePasswordTransactionMutation,
} = apiUser;
