import { useNavigate } from "react-router-dom";
import {
  useGetDetailSavingBoxQuery,
  useGetHistorySavingBoxQuery,
} from "../../../api/savingBoxApi";
import { useGetListVipQuery } from "../../../api/vipApi";
import Loading from "../../../components/common/loading";
import { useGetUsersInfoQuery } from "../../../redux_toolkit/apiUser";
import "../../components.css";

import ModalNapKet from "./modalNapKet";
import { useState } from "react";

function Money() {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("plus");

  const handleOpen = type => {
    setType(type);
    setOpen(true);
  };

  const { data: dataUserInfo } = useGetUsersInfoQuery();
  console.log(dataUserInfo.level);
  const { data: dataVip, isLoading: isLoadingVip } = useGetListVipQuery();
  const {
    data: dataDetailSaveBox,
    isLoading: isLoadingDetail,
    refetch: refetchDetail,
  } = useGetDetailSavingBoxQuery();
  const navigate = useNavigate();
  console.log(
    dataVip?.data?.find(item => item.level === dataUserInfo?.level)
      ?.saving_box_rate * dataDetailSaveBox?.savingsBox?.amount,
  );
  return (
    <>
      <Loading loading={isLoadingVip && isLoadingDetail} />
      <div className="components-body relative z-[4]">
        <ModalNapKet
          open={open}
          setOpen={setOpen}
          type={type}
          refetchDetail={refetchDetail}
        />
        <h2 className="components-title ">Két Tiết Kiệm</h2>
        <div className="flex rounded-lg bg-slate-50 mt-[70px] ">
          <div className="col-50  ">
            <div className="w-full border-r border-r-gray-300">
              <p className="text-gray-600 py-1 text-[17px]">Lãi suất</p>
              <p className="text-black-1 py-1 text-[17px] font-bold">
                {dataVip?.data?.find(item => item.level === dataUserInfo?.level)
                  ?.saving_box_rate || 0}
                %
              </p>
            </div>
            <button
              className=" bg-[#ffd3d3] p-3 px-10 rounded-lg my-5 text-red-500"
              onClick={() => handleOpen("plus")}
            >
              Nạp két
            </button>
          </div>
          <div
            className="col-50 text-[17px]"
            onClick={() => navigate("/money/history")}
          >
            <p className="text-gray-600 py-1">Thu nhập hàng ngày</p>
            <p className="text-black py-1 font-bold">
              {(
                dataVip?.data?.find(item => item.level === dataUserInfo?.level)
                  ?.saving_box_rate *
                (dataDetailSaveBox?.savingsBox?.amount || 0)
              ).toLocaleString() || 0}{" "}
              đ
            </p>
            <button
              className="text-white bg-red-500 p-3 px-10 rounded-lg my-5"
              onClick={event => {
                event.stopPropagation();
                handleOpen("minus");
              }}
            >
              Rút két
            </button>
          </div>
        </div>
        <div className="money-trading-account">
          <span className="text-white-1 mb-20">Tài khoản đầu tư</span>
          <span className="text-white-2">
            {(dataDetailSaveBox?.savingsBox?.amount || 0).toLocaleString()} đ
          </span>
        </div>
        <div className="money-benefit">
          <span className="text-white-1 mb-20">Tổng lãi</span>
          <span className="text-white-2">
            {(dataDetailSaveBox?.totalSave || 0).toLocaleString()} đ
          </span>
        </div>
        <table className="money-table text-[18px] xs:text-[15px]">
          <tr>
            <th>Cấp VIP</th>
            <th>Lãi suất theo ngày</th>
          </tr>
          {dataVip?.data?.map(item => (
            <tr key={item?.id}>
              <td>VIP{item?.level}</td>
              <td>{item?.saving_box_rate} %</td>
            </tr>
          ))}
        </table>
        <div className="components-content">
          <h2 className="components-content-title text-[17px] xs:text-[15px] font-bold mb-5">
            Hướng dẫn
          </h2>
          <ul className="text-[15px] xs:text-[13px]">
            <li>
              1. Chỉ có thể gửi tiền vào két tiết kiệm bằng số dư có thể rút
            </li>
            <li>
              2. Lợi nhuận từ két tiết kiệm sẽ được cộng thằng vào số dư của bạn
            </li>
            <li>
              3. Công thức thanh toán thu nhập: tỉ lệ lợi nhuận dựa vào cấp vip
              của bạn
            </li>
            <li>
              4. Chu kỳ thanh toán tiền lãi: Thanh toán vào 4 giờ sáng hàng ngày
            </li>
            <li>
              5. Nếu số dư của két lợi nhuận được chuyển ra ngoài trước khi
              thanh toán, việc thanh toán tiền thưởng sẽ không được thực hiện
            </li>
            <li>
              6. Chỉ khi số dư của két tiết kiệm lớn hơn hoặc bằng 1.000.000 thì
              mới được tính lãi suất
            </li>
            <li>
              7. Phải mất một khoảng thời gian nhất định để kiếm lợi nhuận . Nếu
              thu nhập không được nhận nhanh chóng hãy kiên nhẫn chờ đợi. Nếu
              bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với bộ phận chăm sóc
              khách hàng
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default Money;
