import dayjs from "dayjs";
import React from "react";

const TableHistoryGame = ({ history, phien, created }) => {
  return (
    <div className="tab-content mb-5">
      <div className="award_tb">
        {history?.length > 0 ? (
          <table>
            <thead className="text-center">
              <tr className="xs:text-[15px]">
                <td>Phiên</td>
                <td>Kết quả</td>
                <td>Thời gian</td>
              </tr>
            </thead>
            <tbody>
              <tr className="xs:text-[13px]">
                <td>{phien}</td>
                <td style={{ textAlign: "center" }}>Đang chờ kết quả</td>
                <td> {dayjs(created).format("YYYY/MM/DD HH:mm")}</td>
              </tr>
              {history.map(item => {
                return (
                  <tr key={item?.id} className="xs:text-[13px]">
                    <td>{item?.phien}</td>
                    <td className="history_xucsac flex justify-center gap-3">
                      {item?.ketqua.split(",")?.map((item, index) => {
                        return (
                          <div
                            className={Number(item) === 0 ? "a0" : "a1"}
                            key={index}
                          ></div>
                        );
                      })}
                    </td>
                    <td>
                      {dayjs(item?.created_at).format("YYYY/MM/DD HH:mm")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-red-300 p-5">Không có dữ liệu</p>
        )}
      </div>
    </div>
  );
};

export default TableHistoryGame;
