import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Slideshow } from "./components/banner/banner2";
import "./home.css";

import { xsn, xs3m, xd, xx, kn, tx } from "../../utils/const";
import {
  xo_so_3_mien,
  xo_so_nhanh,
  xoc_dia,
  xuc_sac,
  ke_no,
  tai_xiu,
  khuyen_mai,
} from "../images";
import {
  Menu_item_content_1,
  Menu_item_content_2,
  Menu_item_content_3,
  Banner,
  SliderNotification,
} from "../home/components";
import Loading from "../../components/common/loading";
import { useGetUsersInfoQuery } from "../../redux_toolkit/apiUser";

function Home() {
  const { data, isLoading } = useGetUsersInfoQuery();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem("username", data?.username);
      setLoading(false);
    }
  }, [isLoading]);
  let [menu, setMenu] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-wrapper">
      <Loading loading={loading} />
      <div className="home-slider mb-4">
        {/* <Banner /> */}
        <Slideshow />
      </div>
      {/* <SliderNotification /> */}
      {/* <div className="text-left title-listgame relative pl-4 mt-8 mb-4">
        Danh sách
      </div> */}
      <div>
        <div className="flex justify-between my-15 h-[20rem] md:h-[16rem]  sm:h-[13rem] mx-[-0.5rem]">
          <div className="relative cursor-pointer" onClick={() => setMenu(0)}>
            <img src={xo_so_3_mien} className="h-full w-auto" />
            <span className="absolute bottom-6 left-[50%] translate-x-[-50%] text-[1.5rem] text-white whitespace-nowrap md:text-[1.4rem] sm:text-[1.2rem]">
              Xổ số 3 miền
            </span>
          </div>
          <div className="relative cursor-pointer" onClick={() => setMenu(1)}>
            <img src={xo_so_nhanh} className="h-full w-auto" />
            <span className="absolute bottom-6 left-[50%] translate-x-[-50%] text-[1.5rem] text-white whitespace-nowrap md:text-[1.4rem] sm:text-[1.2rem]">
              Xổ số nhanh
            </span>
          </div>
          <div className="relative cursor-pointer" onClick={() => setMenu(2)}>
            <img src={xoc_dia} className="h-full w-auto" />
            <span className="absolute bottom-6 left-[50%] translate-x-[-50%] text-[1.5rem] text-white whitespace-nowrap md:text-[1.4rem] sm:text-[1.2rem]">
              Xóc đĩa
            </span>
          </div>
        </div>
        <div className="menu-box">
          <div className="menu-item-2" onClick={() => setMenu(3)}>
            <img src={xuc_sac} className="menu-img h-[90%]" />
            <span className="menu-text-2">Xúc sắc</span>
          </div>
          <div className="menu-item-2" onClick={() => setMenu(4)}>
            <img src={ke_no} className="menu-img h-[90%]" />
            <span className="menu-text-2">Keno</span>
          </div>
          <div className="menu-item-2" onClick={() => setMenu(5)}>
            <img src={tai_xiu} className="menu-img h-[90%] padding-img" />
            <span className="menu-text-2">Tài xỉu</span>
          </div>
          <Link to="/promotion" className="menu-item-2">
            <img src={khuyen_mai} className="menu-img h-[90%] padding-img" />
            <span className="menu-text-2">Khuyến mãi</span>
          </Link>
        </div>
        <div className="list-items mt-8">
          {menu === 0 && <Menu_item_content_1 obj={xs3m} />}
          {menu === 1 && <Menu_item_content_2 obj={xsn} />}
          {menu === 2 && <Menu_item_content_2 obj={xd} />}
          {menu === 3 && <Menu_item_content_2 obj={xx} />}
          {menu === 4 && Menu_item_content_3(kn)}
          {menu === 5 && Menu_item_content_3(tx)}
        </div>
      </div>
    </div>
  );
}

export default Home;
