import "../components.css";
import {
  useGetProgramAgencyQuery,
  useRegisterAgencyMutation,
} from "../../redux_toolkit/apiAdmin";
import { useEffect, useState } from "react";
import { InputGroupStyle } from "../../components/input/input";
import { FaRegEye, FaRegEyeSlash, FaUser } from "react-icons/fa";
import { BiKey } from "react-icons/bi";
import { GoCodeOfConduct } from "react-icons/go";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsPersonVcard } from "react-icons/bs";
// import { Button } from "@material-tailwind/react";
import Submit_btn from "../../components/common/submit_btn";
import { toast } from "react-toastify";
import Loading from "../../components/common/loading";
const domain = process.env.REACT_APP_DOMAIN;
const agencypath = process.env.REACT_APP_AGENCY;

function Agency() {
  const { data, isLoading } = useGetProgramAgencyQuery();
  const [agency, setAgency] = useState(1);
  const [img, setImg] = useState("");
  const [username, setUsername] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [typePassword, setTypePassword] = useState(true);
  const [typeRePassword, setTypeRePassword] = useState(true);

  const [registerAgency, { data: res }] = useRegisterAgencyMutation();
  useEffect(() => {
    setImg(data?.data.image);
  }, [isLoading]);

  const handleAgency = () => {
    setAgency(2);
    window.open(agencypath + "/account/login");
  };

  const onChangeTypePW = () => {
    setTypePassword(!typePassword);
  };

  const onChangeTypeRePW = () => {
    setTypeRePassword(!typeRePassword);
  };

  const handleRegister = () => {
    if (!username || !pass1 || !fullName || !phone || !pass2) {
      toast.error("Vui lòng nhập đủ thông tin");
    } else if (pass1 !== pass2) {
      toast.error("Mật khẩu xác nhận chưa chính xác");
    } else {
      registerAgency({
        username: username,
        password: pass1,
        fullname: fullName,
        phone: phone,
      });
      setFullName("");
      setPass1("");
      setPass2("");
      setPhone("");
      setUsername("");
    }
  };

  useEffect(() => {
    if (res) {
      if (res.status === true) {
        setFullName("");
        setPass1("");
        setPass2("");
        setPhone("");
        setUsername("");
        toast.success(res.msg);
      } else if (res.status === false) {
        toast.error(res.msg);
      }
    }
  }, [res]);
  return (
    <div className="components-body relative z-[4]">
      <Loading loading={isLoading} />
      <h1 className="components-title">Đại lý</h1>
      <div className="flex justify-center bg-white  rounded-2xl shadow p-4">
        <div className="flex w-[95%] flex-row flex-wrap gap-4 bg-[#ddd] rounded-2xl">
          <div
            className={
              agency === 1
                ? "min-w-[8rem] flex-1 bg-[red] rounded-[1rem] text-white  p-2"
                : "min-w-[8rem] flex-1 bg-[#ddd] rounded-[1rem] text-[#b5b5b5]  p-2"
            }
            onClick={() => setAgency(1)}
          >
            ĐẠI LÝ
          </div>
          <div
            className={
              agency === 2
                ? "min-w-[8rem] flex-1 bg-[red] rounded-[1rem] text-white  p-2"
                : "min-w-[8rem] flex-1 bg-[#ddd] rounded-[1rem] text-[#b5b5b5]  p-2"
            }
            onClick={() => handleAgency()}
          >
            Đăng nhập
          </div>
          <div
            className={
              agency === 3
                ? "min-w-[8rem] flex-1 bg-[red] rounded-[1rem] text-white  p-2"
                : "min-w-[8rem] flex-1 bg-[#ddd] rounded-[1rem] text-[#b5b5b5]  p-2"
            }
            onClick={() => setAgency(3)}
          >
            Đăng ký
          </div>
        </div>
      </div>
      {agency === 1 && (
        <div>
          <img src={domain + img} className="rounded" />
        </div>
      )}
      {agency === 3 && (
        <div className="mx-12 mt-20">
          <InputGroupStyle
            value={username}
            name="username"
            label="Tên đăng nhập*"
            decoration={<FaUser size="2rem" color="red" />}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputGroupStyle
            value={pass1}
            name="password"
            label="Mật khẩu*"
            type={typePassword ? "password" : "text"}
            decoration={<BiKey size="2rem" color="red" />}
            decorationPW={
              typePassword ? (
                <FaRegEyeSlash
                  size="2rem"
                  color="grey"
                  onClick={onChangeTypePW}
                />
              ) : (
                <FaRegEye size="2rem" color="grey" onClick={onChangeTypePW} />
              )
            }
            onChange={(e) => setPass1(e.target.value)}
          />

          <InputGroupStyle
            value={pass2}
            name="re-password"
            label="Nhập lại mật khẩu*"
            type={typeRePassword ? "password" : "text"}
            decoration={<BiKey size="2rem" color="red" />}
            decorationPW={
              typeRePassword ? (
                <FaRegEyeSlash
                  size="2rem"
                  color="grey"
                  onClick={onChangeTypeRePW}
                />
              ) : (
                <FaRegEye size="2rem" color="grey" onClick={onChangeTypeRePW} />
              )
            }
            onChange={(e) => setPass2(e.target.value)}
          />

          <InputGroupStyle
            value={phone}
            name="phone"
            label="Số điện thoại*"
            decoration={<BiSolidPhoneCall size="2rem" color="red" />}
            onChange={(e) => setPhone(e.target.value)}
          />

          <InputGroupStyle
            value={fullName}
            name="fullname"
            label="Họ và tên*"
            decoration={<BsPersonVcard size="2rem" color="red" />}
            onChange={(e) => setFullName(e.target.value)}
          />

          <span className="text-lg text-[red]">
            Lưu ý*: Cần nhập họ và tên chính xác để liên kết ngân hàng và rút
            tiền!
          </span>

          <Submit_btn
            text="Đăng Ký đại lý"
            onClick={handleRegister}
            width={"100%"}
          />
        </div>
      )}
    </div>
  );
}

export default Agency;
