import "../../components.css";
import { useEffect, useState } from "react";
import Submit_btn from "../../../components/common/submit_btn";
import { useGetUsersInfoQuery } from "../../../redux_toolkit/apiUser";
import { toast } from "react-toastify";
import Loading from "../../../components/common/loading";
import Title from "../../../components/common/tittle";
import { checkTokenLogin } from "../../../utils/function";
const domain = process.env.REACT_APP_DOMAIN;
const agencypath = process.env.REACT_APP_AGENCY;

function Invite() {
  const { data, isLoading, isFetching } = useGetUsersInfoQuery();
  useEffect(() => {
    checkTokenLogin();
  }, []);
  return (
    <div className="components-body relative z-[4]">
      <Loading loading={isLoading} />
      <Title name="Mời Bạn bè" />
      <div className="components-content p-2 mt-8 mx-2">
        <h2 className="components-content-title">
          Giới thiệu bạn bè nhận quà liền tay
        </h2>
        <ul className="mt-4">
          <li>Nhận ngay 0đ khi giới thiệu thành công một thành viên mới.</li>
          <li>Nhận ngay 0đ khi thành viên mới tham gia trò chơi lần đầu.</li>
        </ul>
      </div>
      <div className="card_invite">
        <div className="text-invite">
          <h3 className="text-[3rem] font-bold">{data?.username}</h3>
          <p>Mã mời thành viên:</p>
          <span className="text-[2rem] font-bold">{data?.ref}</span>
        </div>
      </div>
    </div>
  );
}

export default Invite;
