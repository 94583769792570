import Countdown from "../../cowndown/cowndown";

function Heading_game_1(text) {
  return (
    <div className="flex w-full py-4">
      <div className="heading-col-50">
        <span className="text-gray-400 text-3xl">Phiên số</span>
        <span className="text-3xl py-4">250</span>
        <button className="detailed-results-button">Hướng dẫn cách chơi</button>
      </div>
      <div className="heading-col-50">
        <span className="text-gray-400 text-2xl mb-6">Thời gian còn lại</span>
        {Countdown("18:00:00")}
      </div>
    </div>
  );
}

export default Heading_game_1;
