import "./profile.css";
import frame from "../../assets/images/vip_1_frame.png";
import avatar from "../../assets/images/vip_1_avatar.jpg";
import { Link } from "react-router-dom";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";
import {
  useGetUsersInfoQuery,
  useGetSettingQuery,
} from "../../redux_toolkit/apiUser";
import { format_money } from "../../utils/function";
import { list_profile } from "../../utils/const";
import { MdNavigateNext } from "react-icons/md";
import { ImExit } from "react-icons/im";
import { checkTokenLogin } from "../../utils/function";
import Avatar from "../../components/user/avatar";
import { useEffect } from "react";
import Loading from "../../components/common/loading";

const domain = process.env.REACT_APP_DOMAIN;

function Profile() {
  const { data, isLoading, isFetching } = useGetUsersInfoQuery();
  const { data: setting, isLoading: isLoadingSetting } = useGetSettingQuery();
  useEffect(() => {
    checkTokenLogin();
  }, []);
  // console.log(data);
  return (
    <div className="z-[4] relative">
      <Loading loading={isLoading || isLoadingSetting} />
      {!isLoading && (
        <div className="profile-account">
          <div className="profile-account-corner">
            <span className="profile-account-corner-left"></span>
            <span className="profile-account-corner-right"></span>
          </div>
          <div className="profile-name-vip">
            <div className="profile-name-vip-left"></div>
            <div className="profile-name-vip-center">
              VIP {data?.level || 0}
            </div>
            <div className="profile-name-vip-right"></div>
          </div>
          {/* <div className="w-[10rem] h-[10rem] relative bg-slate-300">
            <Avatar />
          </div> */}
          <div className="profile-account-content">
            <div className="profile-avatar mb-4">
              <Avatar />
              <img className="avatar" src={domain + data?.avatar} />
              <img className="frame" src={frame} />
            </div>
            <span className="text-[1.4rem] mb-4">{data?.username}</span>
            <div className="flex mb-6">
              <span className="text-[1.4rem]">Mã giới thiệu:</span>
              <span className="text-[1.4rem] font-bold ml-2">
                {data?.ref ?? "Được giới thiệu bởi ADMIN"}
              </span>
            </div>
            <span className="text-[2rem] font-semibold">{`${format_money(
              data?.money
            )}`}</span>
          </div>
          <div className="profile-button-box">
            <Link
              to={setting?.data?.link_cskh}
              target="blank"
              className="flex flex-col px-3 py-[0.6rem] items-center w-[50%]"
            >
              <FaMoneyCheckDollar
                style={{ color: "white", width: "4rem", height: "2rem" }}
              />
              <span className="text-[1.4rem] text-white">Nạp tiền</span>
            </Link>
            <div className="bg-white w-[1px] h-10"></div>
            <Link
              to="/withdraw"
              className="flex flex-col px-3 py-[0.6rem] items-center w-[50%]"
            >
              <GiTakeMyMoney
                style={{ color: "white", width: "4rem", height: "2rem" }}
              />
              <span className="text-[1.4rem] text-white">Rút tiền</span>
            </Link>
          </div>
        </div>
      )}
      <div className="my-16 px-8">
        {list_profile.map((item) => {
          if (item.onClick === true)
            return (
              <Link
                to="/login"
                className="flex relative border-b border-slate-200 py-6 cursor-pointer"
                onClick={() => {
                  localStorage.clear();
                }}
              >
                <div className="text-red-400 mr-6 w-10 h-10">{item.icon}</div>
                <span className="text-slate-800">{item.name}</span>
                <MdNavigateNext className="absolute right-4 text-slate-800 w-10 h-10" />
              </Link>
            );
          return (
            <Link
              to={item.path}
              className="flex relative border-b border-slate-200 py-6"
            >
              <div className="text-red-400 mr-6 w-10 h-10">{item.icon}</div>
              <span className="text-slate-800">{item.name}</span>
              <MdNavigateNext className="absolute right-4 text-slate-800 w-10 h-10" />
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Profile;
