import "./promotion.css";
import { Link } from "react-router-dom";
import promotion_1 from "../../assets/images/promotion_1.jpg";
import promotion_2 from "../../assets/images/promotion_2.jpg";
import { useGetPromotionQuery } from "../../redux_toolkit/apiUser";
import { useState, useEffect } from "react";
import Dialog__KM from "../../components/modal/Dialog_KM";
import { checkTokenLogin } from "../../utils/function";
import Loading from "../../components/common/loading";
import km1 from "../../assets/images/km1.png";
import km2 from "../../assets/images/km2.jpeg";
import km3 from "../../assets/images/km3.png";
import km4 from "../../assets/images/km4.png";
import km5 from "../../assets/images/km1.png";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import des1_km1 from "../../assets/images/des1/km1.jpg";
import des1_km2 from "../../assets/images/des1/km2.jpg";
import { Box } from "@mui/material";
// import km1_ct from "../../assets/images/km1_content.png";
// import km2_ct from "../../assets/images/km2_content.png";
// import km3_ct from "../../assets/images/km3_content.png";
// import km4_ct from "../../assets/images/km4_content.png";
// import km5_ct from "../../assets/images/km5_content.png";

function KM(t, k, c) {
  this.title = t;
  this.km = k;
  this.km_content = c;
}

const km1_ct = `* Áp dụng cho tất cả thành viên thuộc keno5p.com

* Bắt đầu từ hôm nay cho đến khi có thông báo mới

- Mỗi thành viên đã và đang tham gia đặt cược tại keno5.store đều có thể đăng ký trở thành đại lý cấp 1 để được hưởng những ưu đãi đặc quyền duy nhất. 

* Quyền lợi khi trở thành đại lý cấp 1

- Hợp tác đôi bên cùng có lợi với Tỉ lệ 1 ăn 99.5

- Được hưởng lương 12 triệu/tháng (duy trì 06 khách hàng mới mỗi tháng, tổng nạp trên 1,6 tỷ) 

- Hoa hồng đại lý cao nhất lên đến 55% lợi nhuận ròng 

- Được hưởng hoa hồng cấp dưới 3~5% (không giới hạn)

- Không giới hạn số lần rút, số tiền rút trong ngày

* Quy định đặt cược xổ số 

- Không được đặt cược quá 70 số. Nếu đặt cược quá 70 số đơn cược sẽ không được tính, và không thể tham gia chương trình này. 

- Khuyến mãi này chỉ áp dụng cho thành viên thuộc keno5.store. Nếu phát hiện bất kỳ cá nhân hoặc nhóm có hành vi gian lận như cược đối nghịch, cược vượt quá 70 số hoặc có hành vi lạm dụng khuyến mãi... keno5.store có quyền hủy bỏ hoặc khấu trừ các khoản ưu đãi độc quyền và tiền thắng nếu có.

- Cảm ơn quý khách hàng đã tin tưởng và hợp tác cùng keno5.store. Xin cảm ơn!`;

const km2_ct = `* Áp dụng cho tất cả thành viên gửi tiền tại keno5p.com

* Bắt đầu từ 01/03/2022 cho đến khi có thông báo mới

- Nạp lần 1: Tặng 100% tối đa 9.999.000vnd

- Nạp lần 2: Tặng 30% tối đa 2.999.000vnd 

* Chương trình khuyến mãi này chỉ áp dụng cho các trò chơi XỔ SỐ NHANH, KENO, ĐỔ XÚC SẮC

- Bất cứ cá nhân hoặc tập thể nào có hành vi gian lận trục lợi đều được đưa vào danh sách cấm tham gia tại trang web.`;

const km3_ct = `* Áp dụng cho tất cả thành viên thuộc keno5p.com

* Bắt đầu từ 01/02/2023 đến 31/12/2023

- Thành viên được nhận 500 nghìn khi giới thiệu 01 bạn bè tham gia đặt cược tại trang web của chúng tôi, số tiền thưởng được tăng dần theo tỷ lệ khách đăng ký tham gia.

- Cách tính thưởng như sau: Khách hàng A giới thiệu 01 khách hàng mới thưởng 500 nghìn

- Giới thiệu 03 khách mới thưởng 2 triệu  

- Giới thiệu 06 khách mới thưởng 5 triệu  

- Giới thiệu 10 khách mới thưởng 12triệu

- Đặc biệt: Trong 1 tháng nếu khách hàng A giới thiệu trên 15 khách hàng mới được hưởng lương cứng 16 triệu/tháng.`;

const km4_ct = `* Áp dụng cho tất cả thành viên thuộc keno5p.com

* Bắt đầu từ 01/02/2023 cho đến khi có thông báo mới

- Hội viên đặt cược (XỔ SỐ NHANH), (KENO) và (ĐỔ XÚC SẮC) tại keno5p.com bất kể thắng hoặc thua chỉ cần trong tuần số tiền cược của hội viên từ 1.000.000 VNĐ trở lên đều có thể đăng ký tham gia nhận ưu đãi hoàn trả lên đến 1.58%

- Thời gian bắt đầu tính từ 23:59:59 của ngày thứ 6 đến 11:59:59 của ngày thứ 7 tuần tiếp theo, hệ thống tự động cộng số tiền hoàn trả vào tài khoản chính của quý khách trước 16h chiều ngày thứ bảy hàng tuần, sau thời gian này quý khách vẫn chưa nhận được tiền vui lòng liên hệ bộ phận CSKH để được hỗ trợ.`;

const km5_ct = `* Áp dụng cho tất cả thành viên thuộc keno5p.com

* Bắt đầu từ 01/01/2023 đến 31/12/2023 

- Khi gửi tiền qua hình thức chuyển khoản quý khách hàng nhận lại ngay 1% tương ứng (không giới hạn số tiền, lần nạp)

- Sau khi chuyển khoản và thao tác lệnh nạp tiền, hệ thống tự động cộng số tiền khuyến mãi vào tài khoản chính của quý khách, trường hợp nếu quý khách chưa nhận được tiền khuyến mãi nạp vui lòng liên hệ với bộ phận CSKH để được hỗ trợ.`;

const listKM = [
  new KM("HỢP TÁC ĐẠI LÝ HOA HỒNG LÊN TỚI 55%", km1, km1_ct),
  new KM("THƯỞNG NẠP ĐẦU CAO NHẤT 100%", km2, km2_ct),
  new KM("GIỚI THIỆU NGAY THƯỞNG LIỀN TAY", km3, km3_ct),
  new KM("XỔ SỐ NHANH HOÀN CƯỢC CỰC CAO", km4, km4_ct),
  new KM("NẠP QUA BANKING TẶNG THÊM 1% (Không giới hạn)", km5, km5_ct),
];

const listKM_Des1 = [
  { title: "GỬI TIẾT KIỆM NHẬN LÃI HÀNG NGÀY", img: des1_km1, link: "/money" },
  { title: "GIỚI THIỆU NGAY THƯỞNG LIỀN TAY", img: des1_km2, link: "/agency" },
];

const domain = process.env.REACT_APP_DOMAIN;

function Promotion() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetPromotionQuery();
  const [title, setTitle] = useState("");
  const [noidung, setNoidung] = useState();
  const [dialog, setDialog] = useState(false);

  const handleKM = (nd, tit) => {
    setTitle(tit);
    setNoidung(nd);
    setDialog(true);
  };
  useEffect(() => {
    checkTokenLogin();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="promotion-body relative z-[4]">
      <Loading loading={isLoading} />
      <h2 className="mb-[10rem] text-white text-[3.5rem] font-bold	">
        Khuyến mãi
      </h2>
      {!isLoading &&
        listKM_Des1.map((item, i) => {
          return (
            <div key={i} className="promotion-box shadow">
              <img
                src={item.img}
                className="promotion-img"
                onClick={() => navigate(item.link)}
              />

              <span className="promotion-text">{item.title}</span>
            </div>
          );
        })}

      {/* <Dialog__KM
        dialog={dialog}
        toggle={() => setDialog(!dialog)}
        tittle={title}
        img={noidung}
      /> */}
      <Modal
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="popup-backdrop">
          <div className="popup-main">
            <div className="popup-header">{title}</div>
            <div className="popup-content">
              <p className="text-[1.6rem] font-semibold underline-offset-2 underline">
                NỘI DUNG KHUYẾN MÃI
              </p>
              {noidung?.split("\n").map((item, i) => {
                return <p key={i}>{item}</p>;
              })}
            </div>
            <button className="popup-close" onClick={() => setDialog(false)}>
              Đóng
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Promotion;
