import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import "./modal.css";
import { useState } from "react";
import activeTick from "../../assets/images/active_tick.png";

const domain = process.env.REACT_APP_DOMAIN;

export function DialogChangeAvatar({ data, dialogAvatar, ChangeAvatar }) {
  const [imageAvatar, setImageAvatar] = useState("");

  const handleClickAvatar = (image) => {
    setImageAvatar(image);
  };

  return (
    <>
      <Dialog open={dialogAvatar} handler={ChangeAvatar} size="sm" className="">
        <DialogHeader className="dialog-header__style">Avatar</DialogHeader>
        <DialogBody className="text-2xl h-[42rem] overflow-scroll">
          {data?.map((item, i) => {
            return (
              <div key={i}>
                <span>{item.description}</span>
                <div className="flex items-center justify-center flex-wrap">
                  {item.images.map((image, j) => {
                    return (
                      <div key={j} className="relative">
                        <img
                          src={domain + image}
                          width={68}
                          height={68}
                          className={`mx-2 my-2 cursor-pointer image-avatar__style ${
                            imageAvatar === image ? "activeImage" : ""
                          }`}
                          onClick={() => handleClickAvatar(image)}
                        ></img>
                        {imageAvatar === image && (
                          <img
                            src={activeTick}
                            width={15}
                            className="absolute bottom-0 right-[30px]"
                          ></img>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </DialogBody>
        <DialogFooter className="text-3xl">
          <Button
            variant="text"
            color="red"
            onClick={ChangeAvatar}
            className="mr-1"
          >
            <span>Hủy</span>
          </Button>
          <Button
            variant="gradient"
            color="blue"
            onClick={() => ChangeAvatar(imageAvatar)}
          >
            <span>Xác nhận</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
