import NullData from "../../../../components/common/null_data";
import {
  format_money,
  formattheloai,
  convertAndFormatDateTime,
} from "../../../../utils/function";
import { list_xucxac } from "../../../../utils/const";
import { BsClipboardData } from "react-icons/bs";
// import NullData from "../../../../components/common/null_data";
import { GrNext } from "react-icons/gr";
import { MdArrowBackIosNew } from "react-icons/md";

export function HistoryBet({ data, dispatch, state }) {
  const total_page = Math.ceil(data?.total / 50);
  const handleIncrement = () => {
    dispatch({ action: "page", payload: state.page + 1 });
  };
  const handleDecrement = () => {
    dispatch({ action: "page", payload: state.page - 1 });
  };
  //   console.log(dataHistoryBet);
  return (
    <>
      {!data ? (
        <NullData />
      ) : (
        <div className="bg-[aliceblue] rounded shadow  relative">
          <div className="relative">
            <div className="flex flex-col bg-[pink] p-2 rounded shadow">
              <span className="sm:text-[1.2rem] md:text-[1.4rem] font-bold">
                {`Lịch sử tham gia ${
                  data.total === 0 ? "" : `Trang ${state.page} / ${total_page}`
                }`}
              </span>
              <span className="italic text-white font-thin text-[1.2rem] sm:text-[1rem]">{`Kết quả : ${
                data.total === 0 ? "0 " : 50 * (state.page - 1) + 1
              }~${
                50 * state.page > data.total ? data.total : 50 * state.page
              }/Tổng số ${data.total}`}</span>

              {state.page < total_page ? (
                <button
                  className="absolute top-[50%] translate-y-[-50%] right-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-pink-400 text-white p-2 rounded"
                  onClick={handleIncrement}
                >
                  Trang tiếp
                </button>
              ) : (
                ""
              )}

              {state.page > 1 ? (
                <button
                  onClick={handleDecrement}
                  className="absolute top-[50%] translate-y-[-50%] left-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-pink-400 text-white p-2 rounded"
                >
                  Trang trước
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {data.total === 0 && <NullData name="Chưa có dữ liệu cược" />}
          <div className="p-4 max-h-[50rem] overflow-y-auto">
            {data.data.map((item) => {
              return (
                <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                  <div className="flex px-4 mb-2">
                    <span className="font-bold mr-4">{`Thể loại: ${formattheloai(
                      item.theloai
                    )}`}</span>
                    {item.thanhtoan === 0 ? (
                      <span className="bg-blue-400 rounded-lg px-2 text-white">
                        Chờ mở thưởng
                      </span>
                    ) : item.win > 0 ? (
                      <span className="bg-green-400 rounded-lg px-2 text-white">
                        Thắng cược
                      </span>
                    ) : (
                      <span className="bg-[red] rounded-lg px-2 text-white">
                        Thất bại
                      </span>
                    )}
                  </div>
                  <div className="flex  px-4 justify-between">
                    <span className=" text-[red] font-bold text-left">{`Cược: ${format_money(
                      item.money
                    )} `}</span>
                    {item.thanhtoan === 1 && item.win > 0 && (
                      <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                        item.win
                      )} `}</span>
                    )}
                  </div>
                  <div className="flex justify-between">
                    <div className="flex px-4 justify-between">
                      <span>{`Phiên: ${item.phien} `}</span>
                    </div>

                    <div className="flex px-4">
                      <span>Cửa đặt:</span>
                      <span className="font-bold text-[red]">
                        {list_xucxac[item.cuadat]}
                      </span>
                    </div>
                  </div>
                  <div className="flex px-4">
                    {`Thời gian cược: ${convertAndFormatDateTime(
                      item.created_at
                    )}`}
                  </div>
                  {item.thanhtoan === 1 && (
                    <div className="flex px-4">
                      {`Mở thưởng lúc: ${convertAndFormatDateTime(
                        item.updated_at
                      )}`}
                    </div>
                  )}
                </div>
              );
            })}
            {data.data.length !== 0 && state.page === total_page && (
              <div className="w-[100%] my-[2rem] flex justify-center items-center">
                <BsClipboardData className="w-8 h-8" />
                <span className="italic ">Chưa có thêm dữ liệu!</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
