import { useState } from "react";
import TableHistoryGame from "./TableHistoryGame";
import TableHistoryUser from "./TableHistoryUser";

const History = ({ phien, created, history, historyUser, setPageUser }) => {
  const [tab, setTab] = useState("game");

  return (
    <div className="history_game mx-5 xs:mx-0 pb-[100px]">
      <ul className="tab-navigation">
        <li
          className={`${
            tab === "game" ? "active" : ""
          } text-[19px] xs:text-[12px]`}
          onClick={() => setTab("game")}
        >
          Lịch sử trò chơi
        </li>
        <li
          className={`xs:text-[19px] text-[12px] ${
            tab === "my" ? "active" : ""
          }`}
          onClick={() => setTab("my")}
        >
          Lịch sử của tôi
        </li>
      </ul>

      {tab === "game" ? (
        <TableHistoryGame history={history} phien={phien} created={created} />
      ) : (
        <TableHistoryUser historyUser={historyUser} setPageUser={setPageUser} />
      )}
    </div>
  );
};

export default History;
