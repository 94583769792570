import React, { useEffect, useState } from "react";
import { useGetHistorySavingBoxQuery } from "../../../api/savingBoxApi";
import { Pagination } from "@mui/material";
import dayjs from "dayjs";
import Loading from "../../../components/common/loading";

const HistoryKetTietKiem = () => {
  const [page, setPage] = useState(1);
  const { data: dataHistory, isLoading } = useGetHistorySavingBoxQuery({
    page,
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Loading loading={isLoading} />
      <div className="components-body relative z-[4]">
        <h2 className="components-title text-[25px]">Lịch Sử Két Tiết Kiệm</h2>

        <table className="money-table text-[18px] xs:text-[15px] mt-[80px]">
          <tr>
            <th>Đầu tư</th>
            <th>Lãi suất</th>
            <th>Thời gian</th>
          </tr>
          {dataHistory?.data?.map(item => (
            <tr key={item?.id}>
              <td>{(item?.amount || 0).toLocaleString()}</td>
              <td>{(item?.save || 0).toLocaleString()}</td>
              <td>{dayjs(item?.created_at).format("DD/MM/YYYY HH:mm")}</td>
            </tr>
          ))}
        </table>
        <Pagination
          count={dataHistory?.totalPages} // Tổng số trang
          page={page} // Trang hiện tại
          onChange={handlePageChange} // Xử lý khi người dùng chọn trang khác
          color="error"
        />
      </div>
    </>
  );
};

export default HistoryKetTietKiem;
