import Title from "../../components/common/tittle";
import {
  useGetUsersInfoQuery,
  useWithdrawMutation,
} from "../../redux_toolkit/apiUser";

import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import Submit_btn from "../../components/common/submit_btn";
import { ToastContainer, toast } from "react-toastify";
import Dialog__NotLogin from "../../components/modal/Dialog_Not_login";
import Loading from "../../components/common/loading";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import BankBox2 from "../../components/common/bank_box2";

export function Withdraw() {
  // const [money, setMoney] = useState("");
  const [password, setPassword] = useState("");
  const [dialogNotLogin, setDialogNotLogin] = useState(false);
  const [bank, setBank] = useState("");
  const [nameBank, setNameBank] = useState("");
  const [nameuser, setNameuser] = useState("");
  const [stk, setStk] = useState("");
  const [withdraw, { data: res }] = useWithdrawMutation();
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  const [errorMessage, setErrorMessage] = useState("");
  const [money, setMoney] = useState("");

  const validateValue = (value) => {
    const money = value === undefined ? "undefined" : value;
    setMoney(money || " ");

    if (!value) {
      // setClassName("");
    } else if (Number.isNaN(Number(value))) {
      setErrorMessage("Please enter a valid number");
      // setClassName("is-invalid");
    } else {
      // setClassName("is-valid");
    }
  };
  // console.log(dataUserInfo);

  // console.log(typeof bank);

  useEffect(() => {
    if (
      bank ===
      "STK " +
        dataUserInfo?.bank_number +
        " - " +
        dataUserInfo?.bank_user +
        " - " +
        dataUserInfo?.bank_name
    ) {
      setNameBank(dataUserInfo.bank_name);
      setNameuser(dataUserInfo.bank_user);
      setStk(dataUserInfo.bank_number);
    }
    if (bank === dataUserInfo?.bank_name2 + " " + dataUserInfo?.bank_number2) {
      setNameBank(dataUserInfo.bank_name2);
      setNameuser(dataUserInfo.bank_user2);
      setStk(dataUserInfo.bank_number2);
    }
  }, [bank]);

  const handleWithdraw = () => {
    if (!dataUserInfo || dataUserInfo?.status === "error") {
      setDialogNotLogin(true);
      return;
    } else if (!money || !nameBank) {
      toast.error("Nhập thiếu thông tin!");
      return;
    } else if (money > dataUserInfo?.money) {
      toast.error("Số tiền vượt quá số dư khả dụng");
      setMoney("");
      return;
    } else {
      withdraw({
        money,
        bank_name: nameBank,
        bank_number: stk,
        bank_user: nameuser,
      });
      setMoney("");
      setPassword("");
      return;
    }
  };

  useEffect(() => {
    if (res) {
      if (res.status === true) {
        toast.success(res.msg);
        refetchUserInfo();
      } else if (res.status === false) {
        toast.error(res.msg);
      }
    }
  }, [res]);

  const handleChange = (selectedOption) => {
    setBank(selectedOption.value);
  };
  return (
    <div className="px-8">
      <Loading loading={isLoadingUserInfo} />
      <Title name="Rút tiền" />
      {!isLoadingUserInfo && (
        <>
          <div>
            <Link to={"/addbank"}>
              <BankBox2 userinfo={dataUserInfo} />
            </Link>
          </div>
          {dataUserInfo?.bank_name ? (
            <>
              {/* <span>Số tiền đã nạp: {dataUserInfo}</span>
              <span>Số tiền đã chơi: {dataUserInfo}</span>
              <span>Số tiền đã thắng: {dataUserInfo}</span> */}
              <CurrencyInput
                id="validation-example-2-field"
                placeholder="Nhập số tiền cần rút"
                allowDecimals={false}
                maxLength={12}
                className="w-full py-4 px-8 rounded-xl"
                onValueChange={validateValue}
                // suffix={" VND"}
                step={10}
              />
              <div className="flex mt-4  w-[100%] p-2 rounded-2xl">
                <Select
                  onChange={handleChange}
                  className="w-full text-[1.2rem] text-start"
                  options={[
                    "STK " +
                      dataUserInfo.bank_number +
                      " - " +
                      dataUserInfo.bank_user +
                      " - " +
                      dataUserInfo.bank_name,
                    dataUserInfo.bank_name2 && dataUserInfo.bank_number2 // Kiểm tra nếu bank_name2 không null
                      ? "STK " +
                        dataUserInfo.bank_number2 +
                        " - " +
                        dataUserInfo.bank_user +
                        " - " +
                        dataUserInfo.bank_number2
                      : null, // Thêm tùy chọn nếu bank_name2 không null
                  ]
                    .filter((option) => option !== null)
                    .map((option) => ({
                      value: option,
                      label: option,
                    }))}
                  value={bank ? { value: bank, label: bank } : null}
                  placeholder="Chọn ngân hàng"
                ></Select>
              </div>

              {/* <Input
                  placeholder="Nhập mật khẩu rút tiền"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                /> */}
              <Submit_btn
                text="Xác nhận"
                width="100%"
                onClick={handleWithdraw}
              />
            </>
          ) : (
            <>
              {/* <div className="mt-[2rem]"></div> */}
              <p className="mb-8">Vui lòng thêm ngân hàng</p>
              <Link to="/addbank">
                <div className=" border border-1 rounded-3xl border-red-500 text-red-500 bg-white w-full text py-1">
                  Thêm ngân hàng
                </div>
              </Link>
            </>
          )}
        </>
      )}

      <Dialog__NotLogin
        dialog={dialogNotLogin}
        toggle={() => setDialogNotLogin(!dialogNotLogin)}
      />
    </div>
  );
}
