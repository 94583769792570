import { phien_to_ngay, isNumeric } from "../../../../utils/function";
// import Modal_xoso_result_detail from "../../../../components/modal/Dialog_xoso_result_detail";
// import Modal_xoso_history_bet from "../../../../components/modal/modal_history_bet/dialog_xoso_history_bet";
import { useState, useEffect } from "react";
import Dialog_xoso_history_bet from "../../../../components/modal/DialogXosoHistoryBet";
import Dialog_xoso_result_detail from "../../../../components/modal/Dialog_xoso_result_detail";

export const HeadingXoso = ({
  state,
  dispatch,
  message,
  children,
  type,
  dataResultsHistory,
  dataHistoryBet,
  isLoadingHistoryBet,
}) => {
  // console.log(children);
  // console.log(dataResultsHistory);
  const [results, setResults] = useState([]);
  const [more, setMore] = useState(false);
  const [phientiep, setPhientiep] = useState("Chờ quay số");
  // console.log(message);
  useEffect(() => {
    if (message?.xoso?.[type]?.nextPhien?.phien) {
      setPhientiep(phien_to_ngay(message?.xoso?.[type]?.nextPhien?.phien));
    } else {
      setPhientiep("Chờ quay số");
    }
  }, [message?.xoso?.[type]?.nextPhien?.phien]);
  useEffect(() => {
    const interval = setInterval(() => {
      const randomArray = Array.from({ length: 5 }, () =>
        Math.floor(Math.random() * 10)
      );
      setResults(randomArray);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const [modalResultHistory, setModalResultHistory] = useState(false);
  const toggle_result_history = () => setModalResultHistory(false);

  const [modalHistoryBet, setModalHistoryBet] = useState(false);
  const toggle_history_bet = () => setModalHistoryBet(false);

  const [dataModalResult, setDataModalResult] = useState({});
  const [g1_7, setG1_7] = useState([]);
  const [dd, setDd] = useState([]);
  const handleModalResult = (number) => {
    if (dataResultsHistory) {
      const data = dataResultsHistory[number];
      setDataModalResult(dataResultsHistory[number]);
      setG1_7(
        ["g1", "g2", "g3", "g4", "g5", "g6", "g7"].map((item) => data[item])
      );
      setDd(
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].map(
          (item) => data.dauduoi[item]
        )
      );
      setModalResultHistory(true);
    }
  };
  // console.log(message);
  return (
    <>
      <div className="flex items-center rounded-[2.5rem] bg-white relative top-0 z-[3] min-h-[16rem]">
        <div className="flex flex-col items-center w-[50%]">
          <div className="flex flex-col h-[10rem]">
            <div className="flex pt-9 justify-center">
              <span className="lg:text-[1.6rem] md:text-[1.4rem] sm:text-[1.2rem] text-slate-400 mr-2">
                {`${children.menu_name
                  .replace("Miền Trung", "")
                  .replace("Miền Nam", "")}
              ngày:`}
              </span>
              <span className="lg:text-[1.6rem] md:text-[1.4rem] sm:text-[1.2rem] font-bold">
                {phientiep}
              </span>
            </div>
            <span className="font-bold text-blue-600 lg:text-[1.8rem] md:text-[1.5rem] sm:text-[1.2rem] py-4">
              {`Trả kết quả lúc ${children.time}`}
            </span>
          </div>
          <div className="flex py-4">
            <button
              className="history-bet-btn"
              onClick={() => setModalHistoryBet(true)}
            >
              Lịch sử của bạn
            </button>
          </div>
        </div>
        <div className="h-[9rem] w-[4.5px] bg-slate-300"></div>
        <div className="flex flex-col items-center w-[50%]">
          {dataResultsHistory && (
            <div className="flex flex-col h-[10rem]">
              <div className="flex pt-9 justify-center">
                <span className="lg:text-[1.6rem] md:text-[1.4rem] sm:text-[1.2rem] text-slate-400">
                  Kết quả ngày:
                </span>
                <span className="lg:text-[1.6rem] md:text-[1.4rem] sm:text-[1.2rem] font-bold">
                  {phien_to_ngay(dataResultsHistory[0]?.phien)}
                </span>
              </div>
              <div className="flex  py-4 justify-center">
                {dataResultsHistory[0]?.gdb.split("").map((item) => {
                  return (
                    <span
                      className="bg-[red] text-white rounded-full w-8 h-8 leading-8 text-[1.5rem] mr-1 md:w-7 md:h-7 
                    md:leading-7 md:text-[1.4rem] sm:w-6 sm:h-6 sm:leading-6 sm:text-[1.3rem]"
                    >
                      {item}
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          <div className="flex py-4">
            <div>
              <button
                className="detailed-results-btn"
                onClick={() => handleModalResult(0)}
              >
                Chi tiết kết quả
              </button>
              {/* <button
                className="more-btn"
                onClick={() => setMore((value) => !value)}
              >
                Xem thêm
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {more && (
        <div className="w-100% flex justify-center overflow-y-auto max-h-[40rem] wrap-tb mt-4 rounded-lg">
          <table className="table-results-history">
            <tr className="bg-[pink]">
              <th className="">Phiên</th>
              <th className="">Kết quả</th>
            </tr>

            {dataResultsHistory &&
              dataResultsHistory.slice(0, 30).map((item, index) => {
                return (
                  <>
                    <tr onClick={() => handleModalResult(index)}>
                      <td className="w-[12rem]">{item.phien}</td>
                      <td className="w-[18rem]">
                        <div className=" flex justify-center">
                          {item.gdb.split("").map((char) => {
                            return <span className="red-ball">{char}</span>;
                          })}
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
          </table>
        </div>
      )}

      <Dialog_xoso_result_detail
        data={dataModalResult}
        g1_7={g1_7}
        dd={dd}
        dialog={modalResultHistory}
        toggle={toggle_result_history}
      />
      {!isLoadingHistoryBet && (
        <Dialog_xoso_history_bet
          data={dataHistoryBet}
          dialog={modalHistoryBet}
          toggle={toggle_history_bet}
          state={state}
          dispatch={dispatch}
        />
      )}
    </>
  );
};
