import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import "./modal.css";
const domain = process.env.REACT_APP_DOMAIN;
function Dialog__KM({ dialog, toggle, img, tittle }) {
  return (
    <>
      <Dialog open={dialog} handler={toggle}>
        <DialogHeader className="bg-[red]">
          <span className="text-[white] bg-[red]">{tittle}</span>
        </DialogHeader>
        <DialogBody className="w-full"></DialogBody>
        <DialogFooter>
          <Button onClick={toggle} className="bg-[red] m-auto text-white">
            Đóng
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default Dialog__KM;
