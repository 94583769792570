import "./menu_item_content_1.css";
import { Link } from "react-router-dom";

function Menu_item_content_1({ obj }) {
  return (
    <>
      <div className="flex flex-col">
        {/* <div className="text-left title-listgame relative pl-4">Danh sách</div> */}

        {obj.map((item, i) => {
          return (
            <Link
              to={item.path}
              key={i}
              className="my-3 w-full h-[10.2rem] md:h-[8.6rem] sm:h-[8.6rem] bg-cover rounded-2xl"
              style={{ backgroundImage: `url(${item.img})` }}
            ></Link>
          );
        })}
      </div>
    </>
  );
}

export default Menu_item_content_1;
