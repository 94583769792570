export function InputGroupStyle({
  label,
  name,
  value,
  onChange,
  type = "text",
  decoration,
  decorationPW,
  inputClassName = "",
  decorationClassName = "",
  disabled,
  isBorderRadius,
  maxLength,
}) {
  return (
    <div className="flex flex-row-reverse items-stretch w-full my-4 relative">
      <input
        maxLength={maxLength}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={label}
        aria-label={label}
        className={`peer h-[50px] text-2xl shadow-lg block w-full py-3 px-3 text-gray-600 bg-white border border-gray-400 focus:border-red-400 focus:outline-none focus:ring-0 appearance-none rounded-[0.75rem] transition-colors duration-300 ${
          disabled ? "bg-gray-200" : ""
        } ${inputClassName} ${
          isBorderRadius ? "" : "rounded-tl-none rounded-bl-none"
        }`}
        disabled={disabled}
        autoComplete="new-password"
      />
      <div
        className={`flex items-center rounded-tr-none rounded-br-none rounded-[0.75rem] px-3 py-3 text-gray-600 border border-gray-400 border-r-0 ${
          disabled ? "bg-gray-200" : ""
        } ${decorationClassName}`}
      >
        {decoration}
      </div>
      <div className="absolute right-[0px] top-[50%] translate-y-[-50%] translate-x-[-50%]">
        {decorationPW}
      </div>
    </div>
  );
}
