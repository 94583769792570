import { useState } from "react";
import { formatNumber } from "../../../../utils/function";

function Select_number(
  typeSeclect,
  handleSelectType,
  handleSelectNumber,
  hangs,
  handleUnit,
  choose_number,
  handleChangeChonNhanh,
  chonNhanhValue,
  props,
  numberSelected,
  tab2,
  game
) {
  const [group, setGroup] = useState(0);
  // const list_10 = [...Array(10).keys()];
  const list_100 = [...Array(100).keys()];
  // console.log(game);
  const mainArray = [];

  // Tạo 10 mảng con
  for (let i = 0; i < 10; i++) {
    const subArray = [];
    for (let j = 0; j < 100; j++) {
      const number = i * 100 + j;
      subArray.push(number);
    }
    mainArray.push(subArray);
  }

  if (game === "Đề 4 càng đặc biệt") return;
  // console.log(mainArray);

  return (
    <div className="table-number shadow">
      {/* <div className="head">
        <div className="flex flex-row flex-wrap">
          <span
            className={typeSeclect === "Chọn số" ? "title btn-active" : "title"}
            onClick={() => handleSelectType("Chọn số")}
          >
            Chọn số
          </span>
          {props.char === 2 && (
            <span
              className={
                typeSeclect === "Chọn nhanh" ? "title btn-active" : "title"
              }
              onClick={() => handleSelectType("Chọn nhanh")}
            >
              Chọn nhanh
            </span>
          )}
          {props.char !== 1 && (
            <span
              className={
                typeSeclect === "Nhập số" ? "title btn-active" : "title"
              }
              onClick={() => handleSelectType("Nhập số")}
            >
              Nhập số
            </span>
          )}
        </div>

        {typeSeclect !== "Chọn nhanh" && (
          <button
            className="select-num-btn"
            onClick={() => handleSelectNumber(hangs)}
          >
            Xác nhận
          </button>
        )}
      </div> */}
      {/* {typeSeclect === "Chọn số" && (
        <div className="body-table">
          {hangs.map((hang) => {
            return (
              <>
                <div className="table-r">
                  <div className="flex flex-row flex-wrap">
                    <span>{tab2 === "Đầu" ? "Chục" : hang.name}</span>
                    <button
                      className="chonso-btn"
                      onClick={() => hang.func([])}
                    >
                      Xóa
                    </button>
                    <button
                      className="chonso-btn"
                      onClick={() => hang.func(list_10)}
                    >
                      Tất cả
                    </button>
                    <button
                      className="chonso-btn"
                      onClick={() => hang.func([0, 2, 4, 6, 8])}
                    >
                      Chẵn
                    </button>{" "}
                    <button
                      className="chonso-btn"
                      onClick={() => hang.func([1, 3, 5, 7, 9])}
                    >
                      Lẻ
                    </button>
                    <button
                      className="chonso-btn"
                      onClick={() => hang.func([5, 6, 7, 8, 9])}
                    >
                      Tài
                    </button>{" "}
                    <button
                      className="chonso-btn"
                      onClick={() => hang.func([0, 1, 2, 3, 4])}
                    >
                      Xỉu
                    </button>
                  </div>
                  <div className="flex flex-row flex-wrap ">
                    {list_10.map((item, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => handleUnit(hang.func, hang.hang, item)}
                          className={
                            hang.hang.includes(item) ? "item active" : "item"
                          }
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )} */}

      <span className="text-[1.7rem] font-bold border-b-[3px] border-[red]">
        Chọn số
      </span>

      <div className="h-4"></div>
      {game === "Lô 3 càng" && (
        <div className="flex justify-between px-1 pb-4 border-b">
          {mainArray.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex flex-col items-center  w-[9%] text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem] rounded-2xl ${
                  group === index
                    ? "text-white font-bold bg-[#ffc107] shadow"
                    : "border border-[#ffc107]"
                }`}
                onClick={() => setGroup(index)}
              >
                <span
                  className={`border-b w-fit ${
                    group === index ? "border-[white]" : "border-[black]"
                  }`}
                >
                  {formatNumber(item[0], 3)}
                </span>

                <span>{formatNumber(item[99], 3)}</span>
              </div>
            );
          })}
        </div>
      )}
      {game === "Lô 3 càng" && (
        <div className="body-table mt-4">
          <div className="flex flex-row flex-wrap justify-between">
            {mainArray.map((item, i) => {
              return (
                <>
                  {i === group &&
                    item.map((number, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => choose_number(formatNumber(number, 3))}
                          className={
                            numberSelected.includes(formatNumber(number, 3))
                              ? "item-2 active quick"
                              : "item-2 quick"
                          }
                        >
                          {formatNumber(number, 3)}
                        </div>
                      );
                    })}
                </>
              );
            })}
          </div>
        </div>
      )}
      {!["Lô 3 càng", "Đề 4 càng đặc biệt"].includes(game) && (
        <div className="body-table">
          <div className="flex flex-row flex-wrap justify-between">
            {list_100.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => choose_number(formatNumber(item, 2))}
                  className={
                    numberSelected.includes(formatNumber(item, 2))
                      ? "item-2 active quick"
                      : "item-2 quick"
                  }
                >
                  {formatNumber(item, 2)}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* {typeSeclect === "Nhập số" && (
        <div className="input-wrapper">
          <input
            type="string"
            placeholder={props.exp}
            value={chonNhanhValue}
            onChange={handleChangeChonNhanh}
          />
        </div>
      )} */}
    </div>
  );
}

export default Select_number;
