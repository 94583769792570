import "./table_result.css";
import xxh1 from "../../assets/images/xx_history1.png";
import xxh2 from "../../assets/images/xx_history2.png";
import xxh3 from "../../assets/images/xx_history3.png";
import xxh4 from "../../assets/images/xx_history4.png";
import xxh5 from "../../assets/images/xx_history5.png";
import xxh6 from "../../assets/images/xx_history6.png";
import "../../pages/game/game.css";
import dayjs from "dayjs";
import { convertPhien } from "../../utils/function";
import { format_time_table_taixiu } from "../../utils/function";
// import NullData from "../common/null_data";

const xx_history = [xxh1, xxh2, xxh3, xxh4, xxh5, xxh6];

const Table_xucxac = ({ data, phien, created }) => {
  const sum_char = (number) => {
    const digits = number.split(",").map(Number);
    const sum = digits.reduce((acc, digit) => acc + digit, 0);
    return sum;
  };
  return (
    <div className="wrap-table-taixiu">
      <table className="table-taixiu text-[1.3rem] md:text-[1.2rem] sm:text-[1.1rem] relative">
        <thead className="bg-[#f56464]">
          <th className="w-[15%]">Phiên</th>
          <th className="w-[25%]">Kết quả</th>
          <th className="w-[15%]">Tài/xỉu</th>
          <th className="w-[15%]">Chẵn/lẻ</th>
          <th className="w-[30%]">Thời gian</th>
        </thead>
        <tbody>
          <tr className="py-4">
            <td className="w-[15%]">{convertPhien(phien)}</td>
            <td></td>
            <td className="whitespace-nowrap">Đang chờ kết quả</td>
            <td></td>
            <td className="w-[30%]">{dayjs(created).format("HH:mm DD/MM")}</td>
          </tr>
          {data?.map((item, index) => (
            <tr key={index}>
              <td className="w-[15%]">{convertPhien(item.phien)}</td>
              <td className="flex justify-center items-center">
                <img src={xx_history[item.ketqua.split(",")[0] - 1]} />
                <img src={xx_history[item.ketqua.split(",")[1] - 1]} />
                <img src={xx_history[item.ketqua.split(",")[2] - 1]} />
              </td>
              <td
                className={
                  sum_char(item.ketqua) > 10
                    ? "text-green-600"
                    : "text-blue-600"
                }
              >
                {sum_char(item.ketqua) > 10 ? "Tài" : "Xỉu"}
              </td>
              <td
                className={
                  sum_char(item.ketqua) % 2 === 0
                    ? "text-green-600"
                    : "text-blue-600"
                }
              >
                {sum_char(item.ketqua) % 2 === 0 ? "Chẵn" : "Lẻ"}
              </td>
              <td className="w-[25%]">
                {dayjs(item.created_at).format("HH:mm DD/MM")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table_xucxac;
