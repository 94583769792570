function Positive_negative_money({ money }) {
  if (money > 0) {
    return (
      <div className="rounded-lg p-2 text-green-500 font-bold text-[1.8rem]">
        {"+" + new Intl.NumberFormat().format(money) + " đ"}
      </div>
    );
  } else {
    return (
      <div className=" rounded-lg p-2 text-red-500 font-bold text-[1.8rem]">
        {new Intl.NumberFormat().format(money) + " đ"}
      </div>
    );
  }
}

export default Positive_negative_money;
