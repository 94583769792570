import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkToken } from "../utils/function";

const domain = process.env.REACT_APP_DOMAIN;

export const rechargeApi = createApi({
  reducerPath: "rechargeApi",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/user" }),

  endpoints: (builder) => ({
    getListBank: builder.query({
      query: () => ({
        url: `/listbank`,
        headers: headers(),
        method: "GET",
      }),
      transformResponse: (res) => {
        checkToken(res);
        return res.data;
      },
    }),
    addRecharge: builder.mutation({
      query: (params) => ({
        url: `/deposit`,
        headers: headers(),
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetListBankQuery, useAddRechargeMutation } = rechargeApi;
