import "./banner.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { headers } from "../../../../utils/const";
import SliderNotification from "../slider_notification";
import { banner1, banner2, banner3 } from "../../../images";
const domain = process.env.REACT_APP_DOMAIN;
const delay = 2500;

export function Slideshow() {
  const [banners, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const banner = [banner1, banner2, banner3];
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${domain}/api/user/getBanner`, {
        headers,
      })
      .then(function (response) {
        setLoading(false);
        // console.log(response.data);
        let status = response.data.status;
        if (status === true) {
          setBanner(response.data.data);
        }
        return toast.error(response.data.msg);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Lỗi server");
      });
  }, []);

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === banner.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  // console.log(banner);

  return (
    <div className="slideshow relative pb-[40px] rounded-[8px] shadow">
      <div
        className="slideshowSlider "
        style={{ transform: `translate3D(${-index * 100}%, 0, 0)` }}
      >
        {banner.map((item, index) => (
          <img className="slide bg-blue-gray-400" key={index} src={item}></img>
        ))}
      </div>
      <div className="absolute left-0 bottom-0 w-full flex items-center bg-white h-[40px] rounded-b-[8px]">
        <SliderNotification />
      </div>
    </div>
  );
}
