import { useEffect, useState } from "react";
import { useGetHistoryBetQuery } from "../../redux_toolkit/apiUser";
import {
  today,
  today_year_ago,
  format_money,
  formatTime,
  limitStringLength,
  convertAndFormatDateTime,
  isNumeric,
  checkTokenLogin,
  formattheloai,
  convertPhien,
} from "../../utils/function";
import Select from "react-select";
import NullData from "../../components/common/null_data";
import { BsClipboardData } from "react-icons/bs";
import xoso_components from "../../route/id_game";
import {
  list_xucxac,
  list_keno,
  historyplay_game,
  xo_so,
  Xocdia_Cuadat,
  list_keno5,
} from "../../utils/const";
import Loading from "../../components/common/loading";

const listGame = ["Xổ số", "Xóc đĩa", "Xúc xắc", "Sicbo", "Keno"];

function Historyplay() {
  const [game, setGame] = useState("Xổ số");
  const [type, setType] = useState("all");

  const [gameType, setGameType] = useState("lottery");
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useGetHistoryBetQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: gameType,
      providercode: "all",
      page: 1,
    },
    { refetchOnFocus: true }
  );
  useEffect(() => {
    checkTokenLogin();
  }, []);

  // console.log(data);

  const total_page = Math.ceil(data?.total / 50);
  const handleIncrement = () => {
    setPage(page + 1);
  };
  const handleDecrement = () => {
    setPage(page - 1);
  };
  const handleGame = (option) => {
    console.log(option);
    setGame(option.value);
    setGameType(option.code);
    setType(option.type);
  };

  const filteredArray = (str) => {
    return xoso_components.filter((item) => item.path.includes(str));
  };

  console.log(game, gameType, type);

  return (
    <div className="px-4">
      <Loading loading={isLoading || isFetching} />
      <div className="text-white font-bold text-[3rem] py-10 relative z-50">
        Lịch sử tham gia
      </div>
      <div className="w-full relative z-30 flex justify-center mb-8">
        <Select
          onChange={handleGame}
          className="w-[60%]"
          options={historyplay_game.map((option) => ({
            value: option.name,
            label: option.name,
            type: option.type,
            code: option.code,
          }))}
          value={{ value: game, label: game }}
          defaultValue={{ value: game, label: game }}
          placeholder="Chọn trò chơi"
        ></Select>
      </div>

      <div className="relative">
        <div className="flex flex-col bg-[pink] p-2 rounded shadow">
          <span className="sm:text-[1.2rem] md:text-[1.4rem] font-bold">
            {`Lịch sử tham gia ${
              data?.total === 0 ? "" : `Trang ${page} / ${total_page}`
            }`}
          </span>
          <span className="italic text-white font-thin text-[1.2rem] sm:text-[1rem]">{`Kết quả : ${
            data?.total === 0 ? "0 " : 50 * (page - 1) + 1
          }~${50 * page > data?.total ? data?.total : 50 * page}/Tổng số ${
            data?.total
          }`}</span>

          {page < total_page ? (
            <button
              className="absolute top-[50%] translate-y-[-50%] right-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-pink-400 text-white p-2 rounded"
              onClick={handleIncrement}
            >
              Trang tiếp
            </button>
          ) : (
            ""
          )}

          {page > 1 ? (
            <button
              onClick={handleDecrement}
              className="absolute top-[50%] translate-y-[-50%] left-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-pink-400 text-white p-2 rounded"
            >
              Trang trước
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {!isLoading && gameType === "lottery" && (
        <>
          {!data ? (
            <>
              <NullData />
            </>
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 ? (
                <NullData name="Chưa có dữ liệu cược" />
              ) : (
                <div className="p-4 max-h-[50rem] overflow-y-auto">
                  {data.data.map((item) => {
                    return (
                      <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                        <div className="flex px-4 mb-2">
                          <span className="font-bold mr-4">{`Thể loại: ${
                            xo_so[item.type]
                          }`}</span>
                          {item.thanhtoan === 0 ? (
                            <span className="bg-blue-400 rounded-lg px-2 text-white">
                              Chờ mở thưởng
                            </span>
                          ) : item.win > 0 ? (
                            <span className="bg-green-400 rounded-lg px-2 text-white">
                              Thắng cược
                            </span>
                          ) : (
                            <span className="bg-[red] rounded-lg px-2 text-white">
                              Thất bại
                            </span>
                          )}
                        </div>
                        <div className="flex  px-4 justify-between">
                          <span className=" text-blue-400 font-bold text-left">{`Cược: ${format_money(
                            item.cuoc
                          )} `}</span>
                          {item.thanhtoan === 1 && item.win > 0 && (
                            <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                              item.win
                            )} `}</span>
                          )}
                        </div>
                        <div className="flex px-4 justify-between">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                          {isNumeric(item.theloai) ? (
                            <div>
                              <span className="mr-2">Loại:</span>
                              <span className="font-bold">
                                {formatTime(item.theloai)}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span className="mr-2">Loại:</span>
                              <span className="font-bold">
                                {filteredArray(item.theloai)[0].menu_name}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="flex px-4 overflow-hidden">{`Số: ${limitStringLength(
                          item?.so,
                          40
                        )} `}</div>
                        <div className="flex px-4">
                          {`Thời gian cược: ${convertAndFormatDateTime(
                            item.created_at
                          )}`}
                        </div>
                        {item.thanhtoan === 1 && (
                          <div className="flex px-4">
                            {`Mở thưởng lúc: ${convertAndFormatDateTime(
                              item.updated_at
                            )}`}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {data?.length !== 0 && page === total_page && (
            <div className="w-[100%] my-[2rem] flex justify-center items-center">
              <BsClipboardData className="w-8 h-8" />
              <span className="italic ">Chưa có thêm dữ liệu!</span>
            </div>
          )}
          {/* {data?.total === 0 && (
            <>
              <div className="pt-[8rem]"></div>
              <NullData name="Chưa có dữ liệu" />
            </>
          )} */}
        </>
      )}
      {!isLoading && gameType === "xocdia" && (
        <>
          {!data ? (
            <NullData />
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 && <NullData name="Chưa có dữ liệu cược" />}
              <div className="p-4 max-h-[50rem] overflow-y-auto">
                {data.data.map((item) => {
                  return (
                    <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                      <div className="flex px-4 mb-2">
                        <span className="font-bold mr-4">{`Thể loại: ${item.theloai}`}</span>
                        {item.thanhtoan === 0 ? (
                          <span className="bg-blue-400 rounded-lg px-2 text-white">
                            Chờ mở thưởng
                          </span>
                        ) : (
                          <span className="bg-green-400 rounded-lg px-2 text-white">
                            Đã mở thưởng
                          </span>
                        )}
                      </div>
                      <div className="flex  px-4 justify-between">
                        <span className=" text-[red] font-bold text-left">{`Cược: ${format_money(
                          item.cuoc
                        )} `}</span>
                        {item.thanhtoan === 1 ? (
                          <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                            item.win
                          )} `}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="flex justify-between">
                        <div className="flex px-4 justify-between">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                        </div>

                        <div className="flex px-4">
                          <span>Cửa đặt:</span>
                          <span className="font-bold text-[red]">
                            {Xocdia_Cuadat[item.cuadat]}
                          </span>
                        </div>
                      </div>
                      <div className="flex px-4">
                        {`Thời gian cược: ${convertAndFormatDateTime(
                          item.created_at
                        )}`}
                      </div>
                      {item.thanhtoan === 1 && (
                        <div className="flex px-4">
                          {`Mở thưởng lúc: ${convertAndFormatDateTime(
                            item.updated_at
                          )}`}
                        </div>
                      )}
                    </div>
                  );
                })}
                {data.data.length !== 0 && page === total_page && (
                  <div className="w-[100%] my-[2rem] flex justify-center items-center">
                    <BsClipboardData className="w-8 h-8" />
                    <span className="italic ">Chưa có thêm dữ liệu!</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!isLoading && gameType === "xucsac" && (
        <>
          {!data ? (
            <NullData />
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 && <NullData name="Chưa có dữ liệu cược" />}
              <div className="p-4 max-h-[50rem] overflow-y-auto">
                {data.data.map((item) => {
                  return (
                    <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                      <div className="flex px-4 mb-2">
                        <span className="font-bold mr-4">{`Thể loại: ${formattheloai(
                          item.theloai
                        )}`}</span>
                        {item.thanhtoan === 0 ? (
                          <span className="bg-blue-400 rounded-lg px-2 text-white">
                            Chờ mở thưởng
                          </span>
                        ) : item.win > 0 ? (
                          <span className="bg-green-400 rounded-lg px-2 text-white">
                            Thắng cược
                          </span>
                        ) : (
                          <span className="bg-[red] rounded-lg px-2 text-white">
                            Thất bại
                          </span>
                        )}
                      </div>
                      <div className="flex  px-4 justify-between">
                        <span className=" text-blue-400 font-bold text-left">{`Cược: ${format_money(
                          item.money
                        )} `}</span>
                        {item.thanhtoan === 1 && item.win > 0 && (
                          <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                            item.win
                          )} `}</span>
                        )}
                      </div>
                      <div className="flex justify-between md:flex-col sm:flex-col">
                        <div className="flex px-4 text-left">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                        </div>

                        <div className="flex px-4">
                          <span className="mr-2">Cửa đặt:</span>
                          <span className="font-bold text-blue-400 text- text-left">
                            {`${list_xucxac[item.cuadat]}`}
                          </span>
                        </div>
                      </div>
                      <div className="flex px-4">
                        {`Thời gian cược: ${convertAndFormatDateTime(
                          item.created_at
                        )}`}
                      </div>
                      {item.thanhtoan === 1 && (
                        <div className="flex px-4">
                          {`Mở thưởng lúc: ${convertAndFormatDateTime(
                            item.updated_at
                          )}`}
                        </div>
                      )}
                    </div>
                  );
                })}
                {data.data.length !== 0 && page === total_page && (
                  <div className="w-[100%] my-[2rem] flex justify-center items-center">
                    <BsClipboardData className="w-8 h-8" />
                    <span className="italic ">Chưa có thêm dữ liệu!</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!isLoading && gameType === "sicbo" && (
        <>
          {!data ? (
            <NullData />
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 && <NullData name="Chưa có dữ liệu cược" />}
              <div className="p-4 max-h-[50rem] overflow-y-auto">
                {data.data.map((item) => {
                  return (
                    <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                      <div className="flex px-4 mb-2">
                        <span className="font-bold mr-4">{`Thể loại: ${formattheloai(
                          item.theloai
                        )}`}</span>
                        {item.thanhtoan === 0 ? (
                          <span className="bg-blue-400 rounded-lg px-2 text-white">
                            Chờ mở thưởng
                          </span>
                        ) : item.win > 0 ? (
                          <span className="bg-green-400 rounded-lg px-2 text-white">
                            Thắng cược
                          </span>
                        ) : (
                          <span className="bg-[red] rounded-lg px-2 text-white">
                            Thất bại
                          </span>
                        )}
                      </div>
                      <div className="flex  px-4 justify-between">
                        <span className=" text-blue-400 font-bold text-left">{`Cược: ${format_money(
                          item.money
                        )} `}</span>
                        {item.thanhtoan === 1 && item.win > 0 && (
                          <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                            item.win
                          )} `}</span>
                        )}
                      </div>
                      <div className="flex justify-between md:flex-col sm:flex-col">
                        <div className="flex px-4 text-left">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                        </div>

                        <div className="flex px-4">
                          <span className="mr-2">Cửa đặt:</span>
                          <span className="font-bold text-blue-400 text- text-left">
                            {`${list_xucxac[item.cuadat]}`}
                          </span>
                        </div>
                      </div>
                      <div className="flex px-4">
                        {`Thời gian cược: ${convertAndFormatDateTime(
                          item.created_at
                        )}`}
                      </div>
                      {item.thanhtoan === 1 && (
                        <div className="flex px-4">
                          {`Mở thưởng lúc: ${convertAndFormatDateTime(
                            item.updated_at
                          )}`}
                        </div>
                      )}
                    </div>
                  );
                })}
                {data.data.length !== 0 && page === total_page && (
                  <div className="w-[100%] my-[2rem] flex justify-center items-center">
                    <BsClipboardData className="w-8 h-8" />
                    <span className="italic ">Chưa có thêm dữ liệu!</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!isLoading && gameType === "keno5numbers" && (
        <>
          {!data ? (
            <NullData />
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 && <NullData name="Chưa có dữ liệu cược" />}
              <div className="p-4 max-h-[50rem] overflow-y-auto">
                {data.data.map((item) => {
                  return (
                    <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                      <div className="flex px-4 mb-2">
                        <span className="font-bold mr-4">{`Thể loại: ${formattheloai(
                          item.theloai
                        )}`}</span>
                        {item.thanhtoan === 0 ? (
                          <span className="bg-blue-400 rounded-lg px-2 text-white">
                            Chờ mở thưởng
                          </span>
                        ) : item.win > 0 ? (
                          <span className="bg-green-400 rounded-lg px-2 text-white">
                            Thắng cược
                          </span>
                        ) : (
                          <span className="bg-[red] rounded-lg px-2 text-white">
                            Thất bại
                          </span>
                        )}
                      </div>
                      <div className="flex  px-4 justify-between">
                        <span className=" text-blue-400 font-bold text-left">{`Cược: ${format_money(
                          item.money
                        )} `}</span>
                        {item.thanhtoan === 1 && item.win > 0 && (
                          <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                            item.win
                          )} `}</span>
                        )}
                      </div>
                      <div className="flex justify-between md:flex-col sm:flex-col">
                        <div className="flex px-4">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                        </div>
                        <div className="flex px-4">
                          <span>Cửa đặt:</span>
                          <span className="font-bold text-blue-400">
                            {list_keno5[item.cuadat]}
                          </span>
                        </div>
                      </div>
                      <div className="flex px-4">
                        {`Thời gian cược: ${convertAndFormatDateTime(
                          item.created_at
                        )}`}
                      </div>
                      {item.thanhtoan === 1 && (
                        <div className="flex px-4">
                          {`Mở thưởng lúc: ${convertAndFormatDateTime(
                            item.updated_at
                          )}`}
                        </div>
                      )}
                    </div>
                  );
                })}
                {data.data.length !== 0 && page === total_page && (
                  <div className="w-[100%] my-[2rem] flex justify-center items-center">
                    <BsClipboardData className="w-8 h-8" />
                    <span className="italic ">Chưa có thêm dữ liệu!</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Historyplay;
