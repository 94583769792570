import { Link } from "react-router-dom";
import vietllot_logo from "../../../assets/images/vietlot_logo.png";
import { useState } from "react";
import { ImEyeBlocked } from "react-icons/im";
import { ImEye } from "react-icons/im";
// import { formatMoney } from "../../../../../server/src/utils/helpers";
import { format_money } from "../../../utils/function";

function Header_xocdia({ dataUserInfo }) {
  const [hide, setHide] = useState(false);
  return (
    <div>
      <div className="flex h-16 items-center justify-between mx-5">
        <Link to="/">
          <img
            src={vietllot_logo}
            className="w-[170px] xs:w-[132px] mt-5 xs:mt-0"
          ></img>
        </Link>
        <div className="text-[16px] xs:text-[13px] text-white mt-5 xs:mt-0 flex">
          <span>Số dư: </span>
          <div>
            {hide ? (
              <div className="flex items-center">
                <span>******</span>
                <ImEye
                  className="ml-2"
                  size={17}
                  onClick={() => setHide(false)}
                />
              </div>
            ) : (
              <div className="flex items-center">
                <span>{format_money(dataUserInfo?.money)}</span>
                <ImEyeBlocked
                  className="ml-2"
                  size={17}
                  onClick={() => setHide(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header_xocdia;
