import Home from "../pages/home/home";
import Profile from "../pages/profile/profile";
import Promotion from "../pages/promotion/promotion";
import Recharge from "../pages/recharge/recharge";
import Service from "../pages/service/service";
// import Money from "../pages/promotion/invite/index";
import Invite from "../pages/promotion/invite/index";

// import Xucxac3p from "../pages/game/xucxac/xucxac3p";
// import Keno1p from "../pages/game/keno/keno1p";
import Xocdia from "../pages/game/xocdia/index";
import Taixiu from "../pages/game/taixiu/taixiu";
import Layout_xocdia from "../components/Layout/layout_xocdia";

import xoso_components from "./id_game";
import list_game from "./list_game";
import Xsmt from "../pages/game/xoso/xsmt";
// import Xsmn from "../pages/game/xoso/xsmn";
import Register from "../pages/login_register/register";
import Layout_null from "../components/Layout/layout_null";
import Login from "../pages/login_register/login";
import Money from "../pages/promotion/money";
import Xosonhanh from "../pages/game/xosonhanh/xosonhanh";
import History from "../pages/profile/history";
import Historyplay from "../pages/profile/historyplay";
import Password from "../pages/profile/password";
import Bank from "../pages/profile/bank";
import Xoso from "../pages/game/xoso/xoso";
import Layout_account from "../components/Layout/layout_account";
import Xsmn from "../pages/game/xoso/xsmn";
import Xucxac from "../pages/game/xucxac/xucxac";
import Keno from "../pages/game/keno/keno";
import HistoryKetTietKiem from "../pages/promotion/money/historyKetTietKiem";
import Recharge_history from "../pages/profile/recharge_history";
import Withdraw_history from "../pages/profile/withdraw_history";
import { Withdraw } from "../pages/withdraw";
import { gameXoso } from "../utils/const";
import Agency from "../pages/agency";

const publicRoutes = [
  {
    path: "/promotion",
    title: `promotion`,
    component: Promotion,
  },
  {
    path: "/recharge",
    title: `recharge`,
    component: Recharge,
  },
  {
    path: "/",
    title: `Home`,
    component: Home,
  },
  {
    path: "/profile",
    title: `Profile`,
    component: Profile,
  },
  {
    path: "/service",
    title: `Service`,
    component: Service,
  },
  {
    path: "/money",
    title: `Money`,
    component: Money,
  },
  {
    path: "/money/history",
    title: `Money`,
    component: HistoryKetTietKiem,
  },
  {
    path: "/invite",
    title: `Invite`,
    component: Invite,
  },
  {
    path: "/agency",
    title: `Agency`,
    component: Agency,
  },
  {
    path: "/xoso/:type",
    title: "xoso",
    games: gameXoso,
    component: Xoso,
  },
  {
    path: "/xsmt",
    title: "xsmt",
    component: Xsmt,
  },
  {
    path: "/xsmn",
    title: "xsmn",
    component: Xsmn,
  },

  {
    path: "/xucxac/:type",
    title: "xucxac",
    component: Xucxac,
  },

  {
    path: "/keno/:type",
    title: "keno",
    component: Keno,
  },

  {
    path: "/taixiu/:type",
    title: "taixiu",
    component: Taixiu,
  },
  {
    path: "/xocdia/:theloai",
    component: Xocdia,
    layout: Layout_xocdia,
  },
  {
    path: "/register",
    title: "register",
    component: Register,
    layout: Layout_account,
  },
  {
    path: "/login",
    title: "login",
    component: Login,
    layout: Layout_account,
  },
  {
    path: "/history",
    title: "history",
    component: History,
  },
  {
    path: "/historyplay",
    title: "history play",
    component: Historyplay,
  },

  {
    path: "/password",
    title: "password",
    component: Password,
  },
  {
    path: "/recharge_record",
    title: "recharge record",
    component: Recharge_history,
  },
  {
    path: "/withdraw_record",
    title: "withdraw record",
    component: Withdraw_history,
  },
  {
    path: "/withdraw",
    title: "withdraw",
    component: Withdraw,
  },
  {
    path: "/addbank",
    title: "bank",
    component: Bank,
  },

  {
    path: "/xosonhanh/:type",
    title: "xosonhanh",
    component: Xosonhanh,
    games: gameXoso,
  },
];

export default publicRoutes;
