import { useEffect, useRef, useState } from "react";
import { Xocdia_Cuadat } from "../../utils/const";
import dayjs from "dayjs";
const TableHistoryUser = ({ historyUser, setPageUser }) => {
  const tableRef = useRef(null);
  useEffect(() => {
    const table = tableRef.current;
    if (!table) return;

    const handleScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } = table;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPageUser(prev => prev + 1);
      }
    };

    table.addEventListener("scroll", handleScroll);
    return () => {
      table.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="tab-content mb-5">
      <div className="award_tb tb-history_user" ref={tableRef}>
        {historyUser?.length > 0 ? (
          <table className="table-children">
            <thead className="text-center">
              <tr className="xs:text-[15px] text-[17px]">
                <td>Phiên</td>
                <td>Nội dung</td>
                <td>Cược</td>
                <td>Thắng</td>
                <td>Thời gian</td>
              </tr>
            </thead>
            <tbody>
              {historyUser.map(item => {
                return (
                  <tr key={item?.id} className="xs:text-[13px] text-[14px]">
                    <td>{item?.phien}</td>
                    <td className="text-center">
                      {Xocdia_Cuadat[item?.cuadat]}
                    </td>
                    <td>{item?.cuoc.toLocaleString()}</td>
                    <td>
                      {item?.win == null ? (
                        <span className="text-orange-400 text-center">
                          Chờ kết quả
                        </span>
                      ) : (
                        <span
                          className={`text-center ${
                            item?.win > 0 ? "text-green-300" : "text-red-400"
                          }`}
                        >
                          {item?.win.toLocaleString()}
                        </span>
                      )}
                    </td>
                    <td>
                      {dayjs(item?.created_at).format("YYYY/MM/DD HH:mm")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-red-300 p-5">Không có dữ liệu</p>
        )}
      </div>
    </div>
  );
};

export default TableHistoryUser;
