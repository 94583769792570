import { useState } from "react";
import {
  usePutPasswordMutation,
  useChangePasswordTransactionMutation,
  useGetUsersInfoQuery,
} from "../../redux_toolkit/apiUser";
import Input from "../../components/common/input";
import Title from "../../components/common/tittle";
import Submit_btn from "../../components/common/submit_btn";
import { toast } from "react-toastify";
import React, { useEffect, useReducer } from "react";
import { InputGroupStyle } from "../../components/input/input";
import { FaRegEye, FaRegEyeSlash, FaUser } from "react-icons/fa";
import { BiKey } from "react-icons/bi";
import { checkTokenLogin } from "../../utils/function";
import Loading from "../../components/common/loading";
function Password() {
  const [type, setType] = useState();
  const [typePW, setTypePW] = useState(0);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [reNewPass, setReNewPass] = useState("");
  const [typeOldPass, setTypeOldPass] = useState(true);
  const [typeNewPass, setTypeNewPass] = useState(true);
  const [typeReNewPass, setTypeReNewPass] = useState(true);
  console.log(type);
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  console.log(dataUserInfo);

  useEffect(() => {
    if (dataUserInfo) {
      // console.log(!dataUserInfo?.password_v2);
      setType(dataUserInfo.isPWV2);
    }
  }, [dataUserInfo]);

  const [changePassword, { data: resPW }] = usePutPasswordMutation();
  const [changePasswordTransaction, { data: resPWT }] =
    useChangePasswordTransactionMutation();

  useEffect(() => {
    checkTokenLogin();
  }, []);
  const onChangeTypeOPW = () => {
    setTypeOldPass(!typeOldPass);
  };
  const onChangeTypeNPW = () => {
    setTypeNewPass(!typeNewPass);
  };
  const onChangeTypeRNPW = () => {
    setTypeReNewPass(!typeReNewPass);
  };

  useEffect(() => {
    if (resPW) {
      if (resPW.status === true) {
        setOldPass("");
        setNewPass("");
        setReNewPass("");
        toast.success(resPW.msg);
      } else if (resPW.status === false) {
        toast.error(resPW.msg);
      }
    }
    if (resPWT) {
      if (resPWT.status === true) {
        setOldPass("");
        setNewPass("");
        setReNewPass("");
        toast.success(resPWT.msg);
      } else if (resPWT.status === false) {
        toast.error(resPWT.msg);
      }
    }
  }, [resPW, resPWT]);

  const handleSubmit = () => {
    if (typePW === 0) {
      if (!oldPass || !newPass || !reNewPass)
        return toast.warn("Nhập đầy đủ thông tin", { theme: "light" });
      if (newPass !== reNewPass)
        return toast.warn("Mật khẩu mới không trùng khớp", { theme: "light" });
      changePassword({ passwordOld: oldPass, newPassword: newPass });
    } else {
      if (type) {
        if (!oldPass || !newPass || !reNewPass)
          return toast.warn("Nhập đầy đủ thông tin", { theme: "light" });
        if (newPass !== reNewPass)
          return toast.warn("Mật khẩu mới không trùng khớp", {
            theme: "light",
          });

        changePasswordTransaction({
          password_user: oldPass,
          newPassword: newPass,
          type: type,
        });
        setTimeout(() => {
          refetchUserInfo();
        }, 1000);
      } else {
        if (!newPass || !reNewPass)
          return toast.warn("Nhập đầy đủ thông tin", { theme: "light" });
        if (newPass !== reNewPass)
          return toast.warn("Mật khẩu mới không trùng khớp", {
            theme: "light",
          });

        changePasswordTransaction({
          password_user: "hihi",
          newPassword: newPass,
          type: type,
        });
        setTimeout(() => {
          refetchUserInfo();
        }, 1000);
      }
    }
  };
  return (
    <div className="px-8">
      <Loading loading={isLoadingUserInfo} />
      <Title name="Đổi mật khẩu" />
      {/* <div className="flex flex-row flex-wrap justify-between relative z-10 mt-4">
        <div
          className={
            typePW === 0
              ? "w-32 bg-[red] border rounded-2xl text-white min-w-[15rem] flex-1 m-2 p-2"
              : "w-32 bg-[#ddd] border rounded-2xl text-[#b5b5b5] min-w-[15rem] flex-1 m-2 p-2"
          }
          onClick={() => {
            setTypePW(0);
            setNewPass("");
            setOldPass("");
            setReNewPass("");
          }}
        >
          Mật khẩu
        </div>
        <div
          className={
            typePW === 1
              ? "w-32 bg-[red] border rounded-2xl text-white min-w-[15rem] flex-1 m-2 p-2"
              : "w-32 bg-[#ddd] border rounded-2xl text-[#b5b5b5] min-w-[15rem] flex-1 m-2 p-2"
          }
          onClick={() => {
            setTypePW(1);
            setNewPass("");
            setOldPass("");
            setReNewPass("");
          }}
        >
          Mật khẩu rút tiền
        </div>
      </div> */}
      <div className="relative z-10 mt-[5rem]">
        {(type || typePW === 0) && (
          <InputGroupStyle
            value={oldPass}
            name="password"
            label="Mật khẩu*"
            type={typeOldPass ? "password" : "text"}
            decoration={<BiKey size="2rem" color="red" />}
            decorationPW={
              typeOldPass ? (
                <FaRegEyeSlash
                  size="2rem"
                  color="grey"
                  onClick={onChangeTypeOPW}
                />
              ) : (
                <FaRegEye size="2rem" color="grey" onClick={onChangeTypeOPW} />
              )
            }
            onChange={(e) => setOldPass(e.target.value)}
          />
        )}

        <InputGroupStyle
          value={newPass}
          name="password"
          label="Mật khẩu mới*"
          type={typeNewPass ? "password" : "text"}
          decoration={<BiKey size="2rem" color="red" />}
          decorationPW={
            typeNewPass ? (
              <FaRegEyeSlash
                size="2rem"
                color="grey"
                onClick={onChangeTypeNPW}
              />
            ) : (
              <FaRegEye size="2rem" color="grey" onClick={onChangeTypeNPW} />
            )
          }
          onChange={(e) => setNewPass(e.target.value)}
        />
        <InputGroupStyle
          value={reNewPass}
          name="password"
          label="Xác nhận mật khẩu*"
          type={typeReNewPass ? "password" : "text"}
          decoration={<BiKey size="2rem" color="red" />}
          decorationPW={
            typeReNewPass ? (
              <FaRegEyeSlash
                size="2rem"
                color="grey"
                onClick={onChangeTypeRNPW}
              />
            ) : (
              <FaRegEye size="2rem" color="grey" onClick={onChangeTypeRNPW} />
            )
          }
          onChange={(e) => setReNewPass(e.target.value)}
        />
        <Submit_btn onClick={handleSubmit} text="Xác nhận" width="100%" />
      </div>
    </div>
  );
}

export default Password;
